import {atom, SetterOrUpdater} from 'recoil';
import {userTypes} from "../types/user";

export const userState = atom<userTypes>({
    key: 'user',
    default: {
        seq: '',
        email: '',
        nameKr: '',
        nameEng: '',
        telephone: '',
        bizKrName: '',
        bizEngName: '',
        bizEngAddress: '',
        bizNumber: '',
        bizFile: '',
        passportFile: ''
    }
});

export const setUser = (hook: SetterOrUpdater<any>, {seq, email, nameKr, nameEng, telephone, bizKrName, bizEngName, bizEngAddress, bizNumber, bizFile, passportFile}: Pick<userTypes, 'seq' | 'email' | 'nameKr' | 'nameEng' | 'telephone' | 'bizKrName' | 'bizEngName' | 'bizEngAddress' | 'bizNumber' | 'bizFile' | 'passportFile'>) => {
    hook({
        seq,
        email,
        nameKr,
        nameEng,
        telephone,
        bizKrName,
        bizEngName,
        bizEngAddress,
        bizNumber,
        bizFile,
        passportFile
    });
};

export const deleteUser = (hook: SetterOrUpdater<any>) => {
    hook({
        seq: '',
        email: '',
        nameKr: '',
        nameEng: '',
        telephone: '',
        bizKrName: '',
        bizEngName: '',
        bizEngAddress: '',
        bizNumber: ''
    });
};
