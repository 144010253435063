import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    "한국어": {
        translation: {
            header: {
                introduce: "Simple China 소개",
                help: "이용방법",
                plan: "이용요금",
                invoice: "인보이스",
                invoiceList: "내역",
                invoiceWrite: "신청",
                notice: "공지사항",
                qna: "문의하기",
            },
            footer: {
                privacy1: "이용약관",
                privacy2: "개인정보 처리방침",
                timeDesc1: "대한민국 표준시 기준",
                timeDesc2: "평일 : 오전 9:30 ~ 오후 6:30",
                timeDesc3: "점심 : 오후 12:00 ~ 오후 1:00",
                timeDesc4: "공휴일 휴무",
            },
            home: {
                notice: "공지사항",
                more: "더보기",
                exchange: "오늘의 환율",
                china: "중국",
                usa: "미국",
            },
            login: {
                title: "로그인",
                email: "이메일",
                emailDanger: "이메일 형식이 맞지 않습니다.",
                password: "비밀번호",
                passwordDanger: "비밀번호 형식이 맞지 않습니다.",
                signup: "회원가입",
                signupDesc: "아직 회원이 아니신가요?",
                findIdAndPw: "비밀번호 찾기",
                findIdAndPwDesc: "비밀번호를 찾으시겠습니까?",
                button: "로그인"
            },
            signup: {
                title: "회원가입",
                email: "이메일",
                id: "아이디",
                code: "인증번호",
                emailDesc: "로그인 ID로 Email을 사용합니다.",
                emailDanger: "이메일 형식이 맞지 않습니다.",
                codeCheck: "코드를 입력해주세요.",
                password: "비밀번호",
                passwordDesc: "영문, 숫자, 특수문자를 혼합하여 8~20자 이내",
                passwordDanger: "비밀번호 형식이 맞지 않습니다.",
                passwordCheckDanger: "비밀번호가 맞지 않습니다.",
                passwordSubmit: "비밀번호 변경이 완료되었습니다.",
                nameKr: "이름 (한글)",
                nameEng: "이름 (영문)",
                findId: "ID 찾기",
                findIdValidation:'인증내역을 통해 회원 ID를 알려드립니다.',
                findPw: "비밀번호 찾기",
                findPwValidation:'인증내역을 통해 회원 PW를 재설정 합니다.',
                telephone: "연락처",
                bizKrName: "사업자명 (한글)",
                bizKrNameDesc: "사업자등록증상 상호를 입력해 주세요.",
                bizEngName: "사업자명 (영문)",
                bizEngNameDesc: "사업자등록증상 상호를 입력해 주세요.",
                bizEngAddress: "사업자주소 (영문)",
                bizEngAddressDesc: "사업자등록증상 주소를 입력해 주세요.",
                bizNumber: "사업자번호",
                bizNumberDesc: "사업자번호를 입력해주세요.",
                bizFile: "사업자등록증",
                passportFile: "대표자여권사본",
                submit: '확인',
                button: "가입하기"
            },
            mypage: {
                email: "이메일",
                password: "비밀번호",
                nameKr: "이름 (한글)",
                nameEng: "이름 (영문)",
                telephone: "연락처",
                bizKrName: "사업자명 (한글)",
                bizEngName: "사업자명 (영문)",
                bizEngAddress: "사업자주소 (영문)",
                bizNumber: "사업자번호",
                bizFile: "사업자등록증",
                passportFile: "대표자여권사본",
                category1: "내 정보",
                category2: "비밀번호 변경",
                passwordChange: "변경 비밀번호",
                passwordChangeCheck: "변경 비밀번호 확인",
                passwordChangeButton: "변경",
                modify: "수정",
                logout: "로그아웃",
                signout: "회원탈퇴",
            },
            invoice: {
                bizKrName: "사업자명 (한글)",
                bizEngName: "사업자명 (영문)",
                bizEngAddress: "사업자주소 (영문)",
                bizKrCeo: "이름 (한글)",
                bizEngCeo: "이름 (영문)",
                bizNumber: "사업자번호",
                telephone: "연락처",
                price: "신청금액",
                chinaAccountFile: "계좌사본",
                invoiceFile: "인보이스\n다운로드",
                junmoonFile: "송금전문\n업로드",
                jeungbingFile: "수입증빙\n업로드",
                date: "날짜",
                jeungbingFileUpload:'증빙자료 업로드',
                ApplicationAmount: '신청금액 ($) / 최소 신청금액 1,000 USD',
                ApplicationAmountPlaceholder: '1,000USD 이상 입력',
                importItems: '수입예정품목 (영문) / 수량 / 단가 (USD)',
                item: '품목',
                name:'수입예정품목',
                amount:'수량',
                Price:'단가',
                chinaAndName: '* 띄어쓰기 포함 중국통장에 기재된 이름과 일치해야함',
                chinaAccountNumber: '중국 계좌번호',
                chinaAccountBank: '중국 은행명 (한글)',
                chinaAccountOwner: '중국계좌 수취인명 (대표자 영문)',
                addChinaAccountFile: '중국 은행계좌 실사 첨부를 해주세요.',
                chinaAccountFileAttach: '중국 은행계좌 실사 첨부 (필수)',
                attach: '첨부',
                submit: '완료',
                RequestDetails:'신청내역',
                itemStrong: "최초 발행 후 재발행 시에는 이전 송금 자금에 대한 수입증빙 자료를 반드시 업로드 하여야 신청하실 수 있습니다.",

            },
            notice:{
                title: '제목',
                nameKr: '작성자',
                insertDate: '등록일',
                viewCount: '조회수',
                notice: '공지사항',
                noticeTag: '공지',
                inputBox:'검색어를 입력해주세요.',
                search: '검색'
            },
            qna:{
                title: '제목',
                nameKr: '작성자',
                insertDate: '등록일',
                search: '검색',
                inquiry: '문의하기',
                answer:'답변',
                checking: '확인중',
                inputBox:'검색어를 입력해주세요.',
                writing: '글쓰기'
            }
        }
    },
    "영어": {
        translation: {
            header: {
                introduce: "Simple China Introduce",
                help: "Process",
                plan: "Plan",
                invoice: "Invoice",
                invoiceList: "List",
                invoiceWrite: "Request",
                notice: "Notice",
                qna: "QNA",
            },
            footer: {
                privacy1: "Terms of service",
                privacy2: "Privacy Policy",
                timeDesc1: "Korea Standard Time",
                timeDesc2: "Weekday : AM 9:30 ~ PM 6:30",
                timeDesc3: "Lunch : PM 12:00 ~ PM 1:00",
                timeDesc4: "Closed on Public Holidays",
            },
            home: {
                notice: "Notice",
                more: "more",
                exchange: "Today's exchange rate",
                china: "CHINA",
                usa: "USA",
            },
            login: {
                title: "LOGIN",
                email: "Email",
                emailDanger: "Email format does not match.",
                password: "Password",
                passwordDanger: "Password format does not match.",
                signup: "Sign up",
                signupDesc: "Aren't you a member yet?",
                findIdAndPw: "Find password",
                findIdAndPwDesc: "Find your password?",
                button: "LOGIN"
            },
            signup: {
                title: "SIGN UP",
                email: "Email",
                id: "ID",
                code: "Code",
                codeCheck: "Please enter the code.",
                emailDesc: "Use Email as your login ID.",
                emailDanger: "Email format does not match.",
                password: "Password",
                passwordDesc: "Mixing English, numbers, and special characters up to 8 to 20 characters",
                passwordDanger: "Password format does not match.",
                passwordCheckDanger: "The password does not match.",
                passwordSubmit: "Password change has been completed.",
                findId: "Find ID",
                findIdValidation:'member ID through your authentication details.',
                findPw: "Find password",
                findPwValidation:'Reset member PW through authentication details.',
                nameKr: "Name (Korean)",
                nameEng: "Name (English)",
                telephone: "Telephone",
                bizKrName: "Business name (Korean)",
                bizKrNameDesc: "Please enter the business name on the business license.",
                bizEngName: "Business name (English)",
                bizEngNameDesc: "Please enter the business name on the business license.",
                bizEngAddress: "Business address (English)",
                bizEngAddressDesc: "Please enter the business license address.",
                bizNumber: "Business number",
                bizNumberDesc: "Please enter your business number.",
                bizFile: "Business license",
                passportFile: "Passport",
                submit: 'submit',
                button: "SIGN UP"
            },
            mypage: {
                email: "Email",
                password: "Password",
                nameKr: "Name (Korean)",
                nameEng: "Name (English)",
                telephone: "Telephone",
                bizKrName: "Business name (Korean)",
                bizEngName: "Business name (English)",
                bizEngAddress: "Business address (English)",
                bizNumber: "Business number",
                bizFile: "Business license",
                passportFile: "Passport",
                category1: "My information",
                category2: "Change password",
                passwordChange: "Change password",
                passwordChangeCheck: "Confirm change password",
                passwordChangeButton: "SUBMIT",
                modify: "MODIFY",
                logout: "LOGOUT",
                signout: "SIGN OUT",
            },
            invoice: {
                bizKrName: "Business name (Korean)",
                bizEngName: "Business name (English)",
                bizEngAddress: "Business address\n (English)",
                bizKrCeo: "Business name (Korean)",
                bizEngCeo: "Business name (English)",
                bizNumber: "Business number",
                telephone: "Telephone",
                price: "Application amount ($)",
                chinaAccountFile: "chinaAccountFile",
                invoiceFile: "invoiceFile",
                junmoonFile: "remittance professional upload",
                jeungbingFile: "Upload evidence",
                date: "date",
                jeungbingFileUpload:'Upload evidence',
                ApplicationAmount: 'Application amount ($) / Minimum application amount 1,000 USD',
                ApplicationAmountPlaceholder: 'more 1,000USD',
                importItems: 'Expected import item (English) / quantity / unit price (USD)',
                item: 'item',
                name:'Expected import item',
                amount:'amount',
                Price: 'Unit price',
                chinaAndName: '* Must match the name on the Chinese bankbook including spaces',
                chinaAccountNumber: 'Chinese account number',
                chinaAccountBank: 'Bank of China\n (Korean)',
                chinaAccountOwner: 'Chinese account owner name (onwer English)',
                addChinaAccountFile: 'Please attach the due diligence on the Chinese bank account.',
                chinaAccountFileAttach: 'Chinese bank account due diligence attached (required)',
                attach: 'attach',
                submit: 'submit',
                RequestDetails: 'RequestDetails',
                itemStrong: 'If you reissue it after the initial issuance, you must upload the revenue proof data for the previous remittance fund to apply.'
            },
            notice:{
                title: 'Title',
                nameKr: 'Writer',
                insertDate: 'Date',
                viewCount: 'views',
                notice: 'Notice',
                noticeTag: 'notice',
                inputBox:'Please search term.',
                search: 'search'
            },
            qna:{
                title: 'title',
                nameKr: 'Writer',
                insertDate: 'Date',
                search: 'search',
                inquiry: 'Inquiry',
                answer:'answer',
                checking: 'checking',
                inputBox:'Please search term.',
                writing: 'writing'
            }
        }
    }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "한국어",
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
