import styled from "styled-components";
import {LoginTemplate} from "../../components/templates/login";

export const Login = () => {
    return (
        <LoginWrap>
            <LoginTemplate />
        </LoginWrap>
    )
}

const LoginWrap = styled.div`

`
