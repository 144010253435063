import styled from 'styled-components';
import {ReactNode} from "react";
import {colors, fontStyles, typography} from "../../../styles";
import {shapes, sizes} from "../../../types";
import {Spinner1, Spinner2} from "../../../styles/component";

type props = {
    children?: ReactNode;
    width?: string;
    flex?: number;
    theme?: buttonThemes;
    size?: sizes;
    shape?: shapes;
    fontStyle?: fontStyles;
    $isAble?: boolean;
    $isLoading?: boolean;
    onClick?(): void;
}

type styledTypes = Pick<props, 'width' | 'theme' | 'flex' | 'fontStyle' | 'shape' | 'size' | '$isAble'>;

export const buttonThemes = {
    normal: 'normal',
    black: 'black',
    white: 'white',
    border: 'border'
} as const;
export type buttonThemes = typeof buttonThemes[keyof typeof buttonThemes];

const Button = ({children, width, theme, shape, flex, $isAble = true, $isLoading = true, fontStyle, size, onClick}: props) => {
    return (
        <SizeButtonWrap type={'button'} width={width} theme={theme} shape={shape} flex={flex} $isAble={$isAble} fontStyle={fontStyle}
                        onClick={($isAble && $isLoading) ? onClick : () => {}} size={size}>
            {$isLoading ? children : <Spinner2/>}
        </SizeButtonWrap>
    )
}

const ButtonWrap = styled.button<styledTypes>`
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')};
  ${({flex}) => (flex ? `flex: ${flex}` : '')};
  ${({width}) => (width ? `width: ${width}` : '')};
  opacity: ${({$isAble}) => $isAble ? 1 : 0.3};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
`;

const ThemeButtonWrap = styled(ButtonWrap)`
  ${({theme}) => {
    switch (theme) {
      case buttonThemes.white:
        return `
            color: ${colors.black};
            background-color: ${colors.white};
          `;
      case buttonThemes.black:
        return `
            color: ${colors.white};
            background-color: ${colors.black};
          `;
      case buttonThemes.border:
        return `
            color: ${colors.black};
            background-color: #fff;
            border: 1px solid ${colors.black};
          `;
      default:
        return `
            color: ${colors.white};
            background-color: ${colors.red};
        `;
    }
  }};
`

const ShapeButtonWrap = styled(ThemeButtonWrap)`
  ${({shape}) => {
    switch (shape) {
      case shapes.circle:
        return `
            border-radius: 50%;
          `;
      case shapes.rectangle:
        return `
            border-radius: 0;
          `;
      default:
        return `
            border-radius: 8px;
        `;
    }
  }};
`

const SizeButtonWrap = styled(ShapeButtonWrap)`
  ${({size}) => {
    switch (size) {
      case sizes.xs:
        return `
            padding: 6px;
          `
      case sizes.sm:
        return `
            padding: 10px;
          `
      case sizes.md:
        return `
            padding: 14px;
          `
      case sizes.lg:
        return `
            padding: 18px;
          `
      default:
        return `
            padding: 10px;
        `;
    }
  }};
`

export default Button;
