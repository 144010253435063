import styled from "styled-components";
import {HelpTemplate} from "../../components/templates/help";

export const Help = () => {
    return (
        <HelpWrap>
            <HelpTemplate />
        </HelpWrap>
    )
}

const HelpWrap = styled.div`

`
