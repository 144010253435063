import styled from "styled-components";
import React, {ChangeEvent, cloneElement, Dispatch, ReactComponentElement, ReactNode, SetStateAction, useCallback, useEffect, useState} from "react";
import {shapes, sizes} from "../../../types";
import Button from "../../atoms/button";

type props = {
    title: string;
    name: string;
    value: Record<string, any>[];
    setter: Dispatch<SetStateAction<any>>;
    children: ReactComponentElement<any>[];
    columnCount?: number;
    maxLength: number;
}

export const ResponsiveWrap = ({title, name, value, setter, columnCount = 4, maxLength, children}: props) => {
    const [count, setCount] = useState<number>(value.length || 1);
    const [info, setInfo] = useState<any[]>(value || [{}]);

    useEffect(() => {
        if (value) {
            setInfo(value);
            setCount(value.length);
        }
    }, [value]);

    const onCountMinus = useCallback(() => {
        if (count === 1) return false;

        info.pop();
        setter((prev: Record<string, any>) => ({
            ...prev,
            [name]: {
                ...prev[name],
                value: info,
            },
        }));

        setCount(count - 1);
    }, [count, info]);

    const onCountPlus = useCallback(() => {
        if (count === maxLength) {
            alert(`품목은 최대 ${maxLength}개 까지 입력 가능합니다.`)
            return false;
        }

        setCount(count + 1);
    }, [count]);

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const [elName, elIndex] = e?.target.name.split('||') as [string, number];
        const value = e?.target.value as string | number;

        info[elIndex] = {
            ...info[elIndex],
            [elName]: value,
        }

        setter((prev: Record<string, any>) => ({
            ...prev,
            [name]: {
                ...prev[name],
                value: info,
                validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),
            },
        }));
    }, [info]);

    const onChildren = useCallback(() => {
        const result = [];

        for (let i = 0; i < count; i++) {
            const el = children.map((el: ReactComponentElement<any>, index: number) => {
                if (!el.props.name) return cloneElement(el, {key: index});

                return cloneElement(el, {
                        name: `${el.props.name}||${i}`,
                        index: i,
                        key: index,
                        value: info[i] && info[i][el.props.name],
                        onChange: onChangeInfo,
                    }
                )
            });

            result.push(
                <ResponsiveInputWrap key={i} $columnCount={columnCount}>
                    {el}
                </ResponsiveInputWrap>
            );
        }
        return result;
    }, [count]);

    return (
        <>
            <ResponsiveTitleWrap>
                <span>{title}</span>

                <ResponsiveButtonWrap>
                    <Button onClick={onCountMinus} width={'28px'} shape={shapes.circle} size={sizes.xs}>-</Button>
                    <Button onClick={onCountPlus} width={'28px'} shape={shapes.circle} size={sizes.xs}>+</Button>
                </ResponsiveButtonWrap>
            </ResponsiveTitleWrap>

            {onChildren()}
        </>
    )
}

const ResponsiveTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`

const ResponsiveButtonWrap = styled.div`
  display: flex;
  gap: 10px;
`

const ResponsiveInputWrap = styled.div<{ $columnCount?: number }>`
  display: grid;
  grid-template-columns: ${({$columnCount}) => `repeat(${$columnCount}, 1fr)`};
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  & > span {
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
  }
`
