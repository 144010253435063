import styled from "styled-components";
import {InvoiceWriteTemplate} from "../../components/templates/invoice/write";

export const InvoiceWrite = () => {
    return (
        <InvoiceWriteWrap>
            <InvoiceWriteTemplate />
        </InvoiceWriteWrap>
    )
}

const InvoiceWriteWrap = styled.div`

`
