import styled from "styled-components";
import {InvoiceTemplate} from "../../components/templates/invoice";

export const Invoice = () => {
    return (
        <InvoiceWrap>
            <InvoiceTemplate />
        </InvoiceWrap>
    )
}

const InvoiceWrap = styled.div`

`
