import axios from 'axios';
import {useMutation, useQuery} from "react-query";
import {useCallback} from "react";
import {userTypes} from "../types/user";
import {invoiceTypes} from "../types/invoice";

export const useInsertInvoice = () => {
    const api = useCallback((data: FormData) => {
        return axios.post('/vendor/api/v1/invoice', data);
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useFindInvoices = () => {
    const api = useCallback(() => {
        return axios.get(`/vendor/api/v1/invoice`);
    }, []);

    return useQuery(
        ['invoice'],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useFindInvoice = ({seq}: invoiceTypes) => {
    const api = useCallback(() => {
        return axios.get(`/vendor/api/v1/invoice/${seq}`);
    }, []);

    return useQuery(
        ['invoiceDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useUpdateInvoice = () => {
    const api = useCallback((data: FormData) => {
        return axios.put('/vendor/api/v1/invoice', data);
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}
