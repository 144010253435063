import styled from "styled-components";
import {HomeTemplate} from "../../components/templates/home";

export const Home = () => {
    return (
        <HomeWrap>
            <HomeTemplate />
        </HomeWrap>
    )
}

const HomeWrap = styled.div`

`
