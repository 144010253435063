import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";

export const useFindBanners = () => {
    const api = useCallback(() => {
        return axios.post(`/vendor/api/v1/banner/`);
    }, []);

    return useQuery(
        ['banner'],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
