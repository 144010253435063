import styled from "styled-components";
import {NoticeDetailTemplate} from "../../components/templates/notice/detail";

export const NoticeDetail = () => {
    return (
        <NoticeWrap>
            <NoticeDetailTemplate />
        </NoticeWrap>
    )
}

const NoticeWrap = styled.div`

`
