import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import {sizes} from "../../../types";
import {colors} from "../../../styles";
import {useNavigate} from "react-router-dom";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {Danger} from "../../../styles/component";
import {useLogin} from "../../../api/user";
import {useTranslation} from "react-i18next";

export const LoginTemplate = () => {
    const {t} = useTranslation();
    const {mutate} = useLogin();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        email: {value: '', require: true, validate: false, pattern: regex.email},
        password: {value: '', require: true, validate: false, pattern: regex.password},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        mutate(onTransferData(), {
            onSuccess: async () => {
                navigate('/');
            },
        });
    }, [info]);

    return (
        <LayoutTemplate>
            <LoginWrap>
                <h2>{t('login.title')}</h2>

                <LoginInputItemsWrap>
                    <LoginInputItemWrap>
                        <span>{t('login.email')}</span>
                        <Input width={'100%'} name={'email'} value={info.email.value} onChange={onChangeInfo}/>
                        {(!info.email.validate && info.email.value) && <Danger>{t('login.emailDanger')}</Danger>}
                    </LoginInputItemWrap>

                    <LoginInputItemWrap>
                        <span>{t('login.password')}</span>
                        <Input type={'password'} width={'100%'} name={'password'} value={info.password.value} onChange={onChangeInfo}/>
                        {(!info.password.validate && info.password.value) && <Danger>{t('login.passwordDanger')}</Danger>}
                    </LoginInputItemWrap>

                    <LoginSignUpWrap>
                        <span onClick={() => navigate('/signup')}>{t('login.signupDesc')} <strong>{t('login.signup')}</strong></span>
                    </LoginSignUpWrap>
                    <LoginSignUpWrap>
                        <span onClick={() => navigate('/find/pw')}>{t('login.findIdAndPwDesc')} <strong>{t('login.findIdAndPw')}</strong></span>
                    </LoginSignUpWrap>
                </LoginInputItemsWrap>

                <Button width={'100%'} size={sizes.md} onClick={onSubmit} $isAble={onValidate()}>
                    {t('login.title')}
                </Button>
            </LoginWrap>
        </LayoutTemplate>
    )
}

const LoginWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 80px 40px;

  & > button {
    font-size: 14px;
  }
`

const LoginInputItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
`

const LoginInputItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const LoginSignUpWrap = styled.div`
  font-size: 13px;
  color: ${colors.gray080};
  text-align: right;

  & span {
    cursor: pointer;
  }
`
