import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import Input from "../../atoms/input";
import {FlexWrap} from "../../../styles/component";
import Button, {buttonThemes} from "../../atoms/button";
import {sizes} from "../../../types";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {deleteUser, userState} from "../../../recoil/user";
import {useLogout, useSignout, useUpdateUser} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {colors} from "../../../styles";
import {useTranslation} from "react-i18next";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import InputPreviewFile from "../../molecules/inputPreviewFile";

export const MyInfoTemplate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {email, nameKr, nameEng, bizKrName, bizEngName, bizEngAddress, bizNumber, telephone, bizFile, passportFile} = useRecoilValue(userState);
    const {mutate: logoutMutate} = useLogout();
    const {mutate: signoutMutate} = useSignout();
    const {mutate: updateMutate} = useUpdateUser();
    const setUser = useSetRecoilState(userState);

    const [info, setInfo] = useState<Record<string, any>>({
        nameKr: {value: nameKr, require: true, validate: true, pattern: false},
        nameEng: {value: nameEng, require: true, validate: true, pattern: false},
        bizKrName: {value: bizKrName, require: true, validate: true, pattern: false},
        bizEngName: {value: bizEngName, require: true, validate: true, pattern: false},
        bizEngAddress: {value: bizEngAddress, require: true, validate: true, pattern: false},
        bizNumber: {value: bizNumber, require: true, validate: true, pattern: false},
        telephone: {value: telephone, require: true, validate: true, pattern: false},
        bizFile: {value: bizFile, require: true, validate: true, pattern: false},
        passportFile: {value: passportFile, require: true, validate: true, pattern: false},
    });

    useEffect(() => {
        if (nameKr) setInfo({
            nameKr: {value: nameKr, require: true, validate: true, pattern: false},
            nameEng: {value: nameEng, require: true, validate: true, pattern: false},
            bizKrName: {value: bizKrName, require: true, validate: true, pattern: false},
            bizEngName: {value: bizEngName, require: true, validate: true, pattern: false},
            bizEngAddress: {value: bizEngAddress, require: true, validate: true, pattern: false},
            bizNumber: {value: bizNumber, require: true, validate: true, pattern: false},
            telephone: {value: telephone, require: true, validate: true, pattern: false},
            bizFile: {value: bizFile, require: true, validate: true, pattern: false},
            passportFile: {value: passportFile, require: true, validate: true, pattern: false},
        })
    }, [nameKr]);

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onUpdate = useCallback(() => {
        if (window.confirm('정보를 수정하시겠습니까?')) {
            const info = onTransferData();

            const formData = new FormData();
            formData.append('data', JSON.stringify(info));
            if (info.bizFile) formData.append('bizFile', info.bizFile);
            if (info.passportFile) formData.append('passportFile', info.passportFile);

            updateMutate(formData, {
                onSuccess: () => {
                    window.location.reload();
                }
            });
        }
    }, [info]);

    const onLogout = useCallback(() => {
        logoutMutate(undefined, {
            onSuccess: () => {
                navigate('/');
                deleteUser(setUser);
            }
        });
    }, []);

    const onSignout = useCallback(() => {
        if (window.confirm('정말 탈퇴하시겠습니까?\n회원 탈퇴시 회원 정보는 즉시 삭제됩니다.')) {
            signoutMutate(undefined, {
                onSuccess: () => {
                    navigate('/');
                    deleteUser(setUser);
                }
            });
        }
    }, []);

    return (
        <MyPageContentWrap>
            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.email')}</span>
                <Input width={'100%'} name={'email'} value={email} disabled/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.nameKr')}</span>
                <Input width={'100%'} name={'nameKr'} value={info.nameKr.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.nameEng')}</span>
                <Input width={'100%'} name={'nameEng'} value={info.nameEng.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.telephone')}</span>
                <Input type={'number'} width={'100%'} name={'telephone'} value={info.telephone.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.bizKrName')}</span>
                <Input width={'100%'} name={'bizKrName'} value={info.bizKrName.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.bizEngName')}</span>
                <Input width={'100%'} name={'bizEngName'} value={info.bizEngName.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.bizEngAddress')}</span>
                <Input width={'100%'} name={'bizEngAddress'} value={info.bizEngAddress.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.bizNumber')}</span>
                <Input width={'100%'} name={'bizNumber'} value={info.bizNumber.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.bizFile')}</span>
                <InputPreviewFile id={'file1'} name={'bizFile'} accept={'image/*'} initUrl={info.bizFile.value} setter={setInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.passportFile')}</span>
                <InputPreviewFile id={'file2'} name={'passportFile'} accept={'image/*'} initUrl={info.passportFile.value} setter={setInfo}/>
            </FlexWrap>

            <Button width={'100%'} size={sizes.md} onClick={onUpdate} $isAble={onValidate()}>{t('mypage.modify')}</Button>
            <Button width={'100%'} size={sizes.md} onClick={onLogout}>{t('mypage.logout')}</Button>
            <Button width={'100%'} size={sizes.md} theme={buttonThemes.black} onClick={onSignout}>{t('mypage.signout')}</Button>
        </MyPageContentWrap>
    )
}

const MyPageContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  width: 100%;

  & > button {
    margin-top: 10px;
  }
`

const FilePreviewWrap = styled.img`
  width: 100%;
  color: #DFDFDF;
  border: 1px solid ${colors.gray010};
  border-radius: 6px;
  display: flex;
  padding: 10px 0;
  min-height: 100px;
  align-items: center;
  justify-content: center;
`
