import styled from "styled-components";
import {useCallback, useState} from "react";
import Input from "../../atoms/input";
import {Danger, FlexWrap} from "../../../styles/component";
import Button from "../../atoms/button";
import {sizes} from "../../../types";
import {useUpdateUserPassword} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {regex} from "../../../constants/patterns";
import {useTranslation} from "react-i18next";

export const PasswordTemplate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {mutate} = useUpdateUserPassword();
    const [info, setInfo] = useState<Record<string, any>>({
        password: {value: '', require: true, validate: false, pattern: regex.password},
        passwordCheck: {value: '', require: true, validate: false, pattern: regex.password},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        if (info.password.value !== info.passwordCheck.value) {
            alert('입력하신 비밀번호가 맞지 않습니다.');
            return false;
        }

        mutate(onTransferData(), {
            onSuccess: async () => {
                alert('비밀번호 변경이 완료되었습니다.');
                navigate('/');
            },
        });
    }, [info]);

    return (
        <MyPageContentWrap>
            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.passwordChange')}</span>
                <Input width={'100%'} name={'password'} value={info.password.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <FlexWrap $columns={true} $gap={10}>
                <span>{t('mypage.passwordChangeCheck')}</span>
                <Input width={'100%'} name={'passwordCheck'} value={info.passwordCheck.value} onChange={onChangeInfo}/>
            </FlexWrap>

            <Button width={'100%'} size={sizes.md} onClick={onSubmit} $isAble={onValidate()}>{t('mypage.passwordChangeButton')}</Button>
        </MyPageContentWrap>
    )
}

const MyPageContentWrap = styled.div`
  font-size: 14px;
  width: 100%;

  & > button {
    margin-top: 20px;
  }
`
