import styled from "styled-components";
import {QnaTemplate} from "../../components/templates/qna";

export const Qna = () => {
    return (
        <QnaWrap>
            <QnaTemplate />
        </QnaWrap>
    )
}

const QnaWrap = styled.div`

`
