import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Input from "../../atoms/input";
import {colors} from "../../../styles";
import Button, {buttonThemes} from "../../atoms/button";

export const HelpTemplate = () => {
    return (
        <LayoutTemplate>
            <HelpWrap>
                <HelpHeaderWrap>
                    <div>인보이스 신청</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                    <div>인보이스 발행</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                    <div>은행 송금</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                    <div>송금 전문<br/>업로드</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                    <div>중국법인<br/>수취확인</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                    <div>중국계좌로 입금</div>
                </HelpHeaderWrap>

                <HelpMainWrap>
                    <HelpMainLeftWrap>
                        <HelpMainItemsWrap>
                            <HelpMainItemWrap>
                                <span>사업자 상호명 (영문)</span>
                                <Input placeholder={'사업자 상호명 (영문)'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>사업자 상호명 (한글)</span>
                                <Input placeholder={'사업자 상호명 (한글)'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>사업자 주소 (영문)</span>
                                <Input placeholder={'사업자 주소 (영문)'} width={'150px'} disabled/>
                            </HelpMainItemWrap>
                        </HelpMainItemsWrap>

                        <HelpMainItemsWrap>
                            <HelpMainItemWrap>
                                <span>대표자명 (영문)</span>
                                <Input placeholder={'대표자명 (영문)'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>대표자명 (한글)</span>
                                <Input placeholder={'대표자명 (한글)'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>사업자 번호 ('-' 없이 숫자만)</span>
                                <Input placeholder={'- 없이 숫자만'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>연락처 (ex. 10-1234-1234)</span>
                                <Input placeholder={'10-1234-1234'} width={'150px'} disabled/>
                            </HelpMainItemWrap>
                        </HelpMainItemsWrap>

                        <hr/>

                        <HelpMainItemsWrap>
                            <HelpMainItemWrap>
                                <span>신청금액 ($) / 최소 신청금액 1,000 USD</span>
                                <Input placeholder={'1,000USD 이상 입력'} width={'150px'} disabled/>
                            </HelpMainItemWrap>
                        </HelpMainItemsWrap>

                        <HelpMainItemsWrap>
                            <HelpMainItemWrap>
                                <span>수입예정품목 (영문) / 수량 / 단가 (USD)</span>

                                <HelpMainMultiItemWrap>
                                    <span>품목1</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>

                                    <span>품목5</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>
                                </HelpMainMultiItemWrap>

                                <HelpMainMultiItemWrap>
                                    <span>품목2</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>

                                    <span>품목6</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>
                                </HelpMainMultiItemWrap>

                                <HelpMainMultiItemWrap>
                                    <span>품목3</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>

                                    <span>품목7</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>
                                </HelpMainMultiItemWrap>

                                <HelpMainMultiItemWrap>
                                    <span>품목4</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>

                                    <span>품목8</span>
                                    <Input placeholder={'수입예정품목'} width={'100px'} disabled/>
                                    <Input placeholder={'수량'} width={'60px'} disabled/>
                                    <Input placeholder={'단가'} width={'60px'} disabled/>
                                </HelpMainMultiItemWrap>
                            </HelpMainItemWrap>
                        </HelpMainItemsWrap>

                        <HelpMainTotalWrap>
                            <h3>Total ($): 0.00</h3>
                            <span>* 띄어쓰기 포함 중국통장에 기재된 이름과 일치해야함</span>
                        </HelpMainTotalWrap>

                        <hr/>

                        <HelpMainItemsWrap>
                            <HelpMainItemWrap>
                                <span>중국 계좌번호</span>
                                <Input placeholder={'중국 계좌번호'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>중국 은행명 (한글)</span>
                                <Input placeholder={'중국 은행명 (한글)'} width={'150px'} disabled/>
                            </HelpMainItemWrap>

                            <HelpMainItemWrap>
                                <span>중국계좌 수취인명 (대문자 영문)</span>
                                <Input placeholder={'중국계좌 수취인명 (대문자 영문)'} width={'150px'} disabled/>
                                <span>* 띄어쓰기 포함 중국통장에 기재된 이름과 일치해야함</span>
                            </HelpMainItemWrap>
                        </HelpMainItemsWrap>

                        <HelpMainItemWrap>
                            <span>중국 은행계좌 실사 첨부 (필수) </span>
                            <Button theme={buttonThemes.black} width={'80px'}>
                                첨부하기
                            </Button>
                        </HelpMainItemWrap>
                    </HelpMainLeftWrap>

                    <HelpMainRightWrap>
                        <HelpMainDescItemWrap>
                            <span>1</span>
                            <span>
                                사업자등록증의 상호명을 영문으로 기재합니다. <br/>
                                (송금은 반드시 해당 영문이름으로 하셔야 합니다.)
                            </span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>2</span>
                            <span>사업자등록증의 상호명을 한글로 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>3</span>
                            <span>사업자등록증의 주소를 영문으로 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>4</span>
                            <span>대표자명을 영문으로 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>5</span>
                            <span>대표자명을 한글로 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>6</span>
                            <span>‘-’(하이픈)을 제외한 사업자번호를 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>7</span>
                            <span>연락 가능한 연락처를 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>8</span>
                            <span>
                                신청하실 송금액을 기재합니다.<br/>화폐단위는 미화 ‘USD’ 기준입니다.
                            </span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>9</span>
                            <span>
                                사전무역대금 송금시 어떤 품목을 취급할 것인지에 <br/>
                                대한 소명을 위한 자료입니다. <br/>
                                정확한 수입품목이 정해지지 않았더라도 <br/>
                                대략적인 카테고리를 기재해주셔야 합니다.
                            </span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>10</span>
                            <span>중국 은행 계좌번호를 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>11</span>
                            <span>중국 은행명을 기재합니다.</span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>12</span>
                            <span>
                                중국 계좌의 수취인명을 띄어쓰기를 포함하여 <br/>
                                영문 대문자로 동일하게 기재합니다.
                            </span>
                        </HelpMainDescItemWrap>

                        <HelpMainDescItemWrap>
                            <span>13</span>
                            <span>중국 계좌 사본(이미지)을 첨부합니다.</span>
                        </HelpMainDescItemWrap>
                    </HelpMainRightWrap>
                </HelpMainWrap>
            </HelpWrap>
        </LayoutTemplate>
    )
}

const HelpWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  gap: 80px;
`

const HelpHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 60px;

  & > div {
    flex: 1;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    background-color: ${colors.black};
    border-radius: 8px;
  }
`

const HelpMainWrap = styled.div`
  display: flex;
  gap: 30px;
`

const HelpMainLeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const HelpMainRightWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  background-color: ${colors.gray005};
  border-radius: 8px;
  padding: 20px;
`

const HelpMainItemsWrap = styled.div`
  display: flex;
  gap: 30px;
`

const HelpMainItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
  font-weight: bold;

  & strong {
    color: ${colors.red};
  }
  
  & button {
    cursor: inherit;
  }
`

const HelpMainMultiItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-weight: bold;
  
  & > span {
    min-width: 40px;
  }
`

const HelpMainTotalWrap = styled.div`
  line-height: 25px;

  & > span {
    color: ${colors.red};
    font-size: 14px;
  }
`;

const HelpMainDescItemWrap = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  align-items: center;

  & span:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: ${colors.white};
    background-color: ${colors.red};
    width: 28px;
    height: 28px;
  }
`
