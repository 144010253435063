import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {colors} from "../../../styles";
import Input from "../../atoms/input";
import {Editor} from "../../atoms/editor";
import {useCallback, useState} from "react";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import Button from "../../atoms/button";
import {useInsertQna} from "../../../api/qna";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const QnaWriteTemplate = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {mutate, isLoading} = useInsertQna();
    const [info, setInfo] = useState<Record<string, any>>({
        title: {value: '', require: true, validate: false, pattern: false},
        content: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        if (!onValidate()) {
            alert('제목과 내용을 입력해주세요.');
            return false;
        }

        mutate(onTransferData(), {
            onSuccess: () => {
                navigate('/qna');
            }
        });
    }, [info]);

    return (
        <LayoutTemplate>
            <QnaWriteWrap>
                <h2>{t('qna.inquiry')}</h2>
                <Button width={'80px'} onClick={onSubmit} $isLoading={!isLoading}>{t('qna.writing')}</Button>

                <QnaWriteMainWrap>
                    <QnaWriteHeaderTopWrap>
                        <Input width={'100%'} placeholder={t('qna.title')} name={'title'} value={info.title.value} onChange={onChangeInfo}/>
                    </QnaWriteHeaderTopWrap>

                    <QnaWriteContentWrap>
                        <Editor value={info.content.value} name={'content'} setter={setInfo} isUploader={false}/>
                    </QnaWriteContentWrap>
                </QnaWriteMainWrap>
            </QnaWriteWrap>
        </LayoutTemplate>
    )
}

const QnaWriteWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  gap: 30px;

  & > button {
    font-size: 14px;
    margin: 0 0 0 auto;
  }
`

const QnaWriteHeaderTopWrap = styled.div`
  background-color: ${colors.gray005};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.gray010};
  border-bottom: 1px solid ${colors.gray010};

  & > span {
    color: ${colors.gray030};
    font-size: 14px;
  }
`

const QnaWriteContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  border-bottom: 1px solid ${colors.gray010};
  min-height: 500px;
  
  & > div {
    flex: 1;
  }
`

const QnaWriteMainWrap = styled.div`
    
`
