import styled from "styled-components";
import {SignupCompTemplate} from "../../components/templates/signup/complete";

export const SignupComp = () => {
    return (
        <SignupWrap>
            <SignupCompTemplate />
        </SignupWrap>
    )
}

const SignupWrap = styled.div`

`
