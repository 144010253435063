import styled from "styled-components";
import {QnaWriteTemplate} from "../../components/templates/qna/write";

export const QnaWrite = () => {
    return (
        <QnaWrap>
            <QnaWriteTemplate />
        </QnaWrap>
    )
}

const QnaWrap = styled.div`

`
