import china from '../../../assets/images/china.png';
import usa from '../../../assets/images/usa.png';
import styled from "styled-components";
import {colors} from "../../../styles";
import {inputThemes} from "../../atoms/input";
import {LayoutTemplate} from "../layout";
import {useFindNotices} from "../../../api/notice";
import {useNavigate} from "react-router-dom";
import {noticeTypes} from "../../../types/notice";
import {useFindExchange} from "../../../api/exchange";
import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import InputChildren, {directions} from "../../molecules/inputChildren";
import {useTranslation} from "react-i18next";
import {useFindBanners} from "../../../api/banner";
import Slider from "react-slick";
import {bannerTypes} from "../../../types/banner";
import "slick-carousel/slick/slick.css"
import {Popup} from "../../atoms/popup";

export const HomeTemplate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {data: notices, isLoading} = useFindNotices();
    const {data: banners} = useFindBanners();
    const {data: exchange} = useFindExchange();
    const slickRef = useRef<Slider>(null);
    const [info, setInfo] = useState({cny: 1, usd: 1});

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        if (name === 'cny') {
            info.cny = Number(value);
            info.usd = Number((Number(value) / exchange?.basePrice).toFixed(3));
        }

        if (name === 'usd') {
            info.usd = Number(value);
            info.cny = Number((Number(value) * exchange?.basePrice).toFixed(3));
        }

        setInfo((prev) => ({...info}));
    }, [info, exchange]);


    useEffect(() => {
        if (exchange?.usDollarRate) {
            info.cny = 1;
            info.usd = Number(1 * exchange?.usDollarRate.toFixed(3));
            setInfo((prev) => ({...info}));
        }
    }, [exchange]);

    const onPrevious = useCallback(() => slickRef.current && slickRef.current.slickPrev(), []);
    const onNext = useCallback(() => slickRef.current && slickRef.current.slickNext(), []);

    return (
        <LayoutTemplate>
            <HomeWrap>
                <HomeBannerWrap>
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" fill="none"
                         onClick={onPrevious}
                         stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m15 18-6-6 6-6"></path>
                    </svg>
                    {
                        banners && banners.data && (
                            <Slider arrows={false} dots={false} ref={slickRef} useTransform={false} autoplay={true} autoplaySpeed={5000}>
                                {
                                    banners.data.map((el: bannerTypes, index: number) => (
                                        <img src={el.bannerFile} alt={'banner'} key={index}/>
                                    ))
                                }
                            </Slider>)
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" fill="none"
                         onClick={onNext}
                         stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </HomeBannerWrap>

                <HomeMainWrap>
                    <HomeMainBoxWrap>
                        <HomeMainBoxHeaderWrap>
                            <span>{t('home.notice')}</span>
                            <span onClick={() => navigate('/notice')}>{t('home.more')}</span>
                        </HomeMainBoxHeaderWrap>

                        <HomeMainBoxItemsWrap>
                            {
                                notices && notices.data && notices.data.slice(0, 5).map((i: noticeTypes, index: number) =>
                                    <span key={index} onClick={() => navigate(`/notice/${i.seq}`)}>{i.title}</span>
                                )
                            }
                        </HomeMainBoxItemsWrap>
                    </HomeMainBoxWrap>

                    <HomeMainBoxWrap>
                        <HomeMainBoxHeaderWrap>
                            <span>{t('home.exchange')}</span>
                        </HomeMainBoxHeaderWrap>

                        <HomeMainBoxExchangeItemsWrap>
                            <HomeMainBoxExchangeItemWrap>
                                <span>{t('home.china')} CNY</span>
                                <img src={china} alt={'china'}/>

                                <InputChildren width={'150px'} direction={directions.right} type={'number'} theme={inputThemes.gray} value={info.cny}
                                               name={'cny'} onChange={onChangeInfo}>
                                    CNY
                                </InputChildren>
                            </HomeMainBoxExchangeItemWrap>

                            <h2>=</h2>

                            <HomeMainBoxExchangeItemWrap>
                                <span>{t('home.usa')} USD</span>
                                <img src={usa} alt={'usa'}/>

                                <InputChildren width={'150px'} direction={directions.right} type={'number'} theme={inputThemes.gray} value={info.usd}
                                               name={'usd'} onChange={onChangeInfo}>
                                    USD
                                </InputChildren>
                            </HomeMainBoxExchangeItemWrap>
                        </HomeMainBoxExchangeItemsWrap>
                    </HomeMainBoxWrap>
                </HomeMainWrap>
            </HomeWrap>

            <Popup />
        </LayoutTemplate>
    );
}

const HomeWrap = styled.div`

`

const HomeBannerWrap = styled.div`
  position: relative;
  min-height: 460px;

  & svg {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 36px);
    z-index: 1;
  }

  & svg:first-child {
    left: 3vw;
  }

  & svg:last-child {
    right: 3vw;
  }

  .slick-dots li.slick-active button:before {
    color: #2E9FFF;
  }

  & img {
    max-height: 460px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const HomeMainWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  gap: 80px;
`

const HomeMainBoxWrap = styled.div`
  flex: 1;
  border: 1px solid ${colors.gray};
  border-radius: 8px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HomeMainBoxHeaderWrap = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  & span:nth-child(2) {
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
  }
`

const HomeMainBoxItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;

  & > span {
    cursor: pointer;
  }
`

const HomeMainBoxExchangeItemsWrap = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
`

const HomeMainBoxExchangeItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & > span {
    font-size: 14px;
  }

  & > img {
    max-width: 120px;
  }

  & input {
    text-align: end;
  }
`
