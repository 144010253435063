import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Input from "../../atoms/input";
import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Button from "../../atoms/button";
import {useFindNotices} from "../../../api/notice";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {noticeTypes} from "../../../types/notice";
import {useNavigate} from "react-router-dom";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import Tag, {tagThemes} from "../../atoms/tag";
import {sizes} from "../../../types";
import {useTranslation} from "react-i18next";

export const NoticeTemplate = () => {
    const navigate = useNavigate();
    const {data: notices, isLoading} = useFindNotices();
    const {page} = useGetQueryString();
    const {t} = useTranslation();
    const [info, setInfo] = useState<Record<string, any>>({
        search: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const columns = useMemo(() => [
        {header: '', accessor: 'tag', width: '10%'},
        {header: t('notice.title'), accessor: 'title', width: '60%'},
        {header: t('notice.nameKr'), accessor: 'nameKr', width: '10%'},
        {header: t('notice.insertDate'), accessor: 'insertDate', width: '10%'},
        {header: t('notice.viewCount'), accessor: 'viewCount', width: '10%'},
    ], []);

    const data = useMemo(() => notices?.data && notices?.data.length > 0 ? notices?.data.map((x: noticeTypes) => {
        return {
            ...x,
            insertDate: x.insertDate?.slice(0, 10),
            tag: <Tag theme={tagThemes.black} size={sizes.sm}>공지</Tag>
        };
    }) : '', [notices]);

    return (
        <LayoutTemplate>
            <NoticeWrap>
                <h2>{t('notice.notice')}</h2>

                <TableHeaderWrap>
                    <Input placeholder={t('notice.inputBox')} name={'search'} value={info.search.value} onChange={onChangeInfo}/>
                    <Button width={'80px'} onClick={() => navigate(`?page=${page || 1}&search=${info.search.value}`)}>{t('notice.search')}</Button>
                </TableHeaderWrap>

                <Table columns={columns} data={data} isLoading={isLoading} onClick={(i) => navigate(i.seq.toString())}/>
                {
                    !isLoading && <Pagination count={notices ? notices?.count[0]?.count || 1 : ''} page={Number(page)}/>
                }
            </NoticeWrap>
        </LayoutTemplate>
    )
}

const NoticeWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  gap: 30px;
`

const TableHeaderWrap = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  font-size: 14px;
`
