import axios from 'axios';
import {useMutation, useQuery} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {noticeTypes} from "../types/notice";

export const useFindNotice = ({seq}: noticeTypes) => {
    const api = useCallback(() => {
        return axios.get(`/vendor/api/v1/board/${seq}`);
    }, []);

    return useQuery(
        ['noticeDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindNotices = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/vendor/api/v1/board/notice/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['notice', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useUpdateNoticeCount = () => {
    const api = useCallback(({seq}: noticeTypes) => {
        return axios.put(`/vendor/api/v1/board/count/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: noticeTypes) => {
            const response = await api({seq});
            return response.data;
        },
    );
}
