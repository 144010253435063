import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {useFindNotice, useUpdateNoticeCount} from "../../../api/notice";
import {colors} from "../../../styles";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

export const NoticeDetailTemplate = () => {
    const {seq} = useParams();
    const {data: notice} = useFindNotice({seq});
    const {mutate} = useUpdateNoticeCount();

    useEffect(() => {
        mutate({seq});
    }, []);

    return (
        <LayoutTemplate>
            {
                notice && notice.seq &&
                <NoticeDetailWrap>
                    <NoticeDetailHeaderTopWrap>
                        <h3>{notice.title}</h3>
                        <span>{notice.insertDate}</span>
                    </NoticeDetailHeaderTopWrap>

                    <NoticeDetailHeaderMiddleWrap>
                        <span>{notice.nameKr}</span>
                        <span>조회 {notice.viewCount}</span>
                    </NoticeDetailHeaderMiddleWrap>

                    <NoticeDetailHeaderBottomWrap>
                        https://simplecn.cn/notice/{notice.seq}
                    </NoticeDetailHeaderBottomWrap>

                    <NoticeDetailContentWrap dangerouslySetInnerHTML={{__html: notice.content}} />
                </NoticeDetailWrap>
            }
        </LayoutTemplate>
    )
}

const NoticeDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
`

const NoticeDetailHeaderTopWrap = styled.div`
  background-color: ${colors.gray005};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid ${colors.red};

  & > span {
    color: ${colors.gray030};
    font-size: 14px;
  }
`

const NoticeDetailHeaderMiddleWrap = styled.div`
  font-size: 14px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray010};

  & > span:last-child {
    color: ${colors.gray030};
  }
`

const NoticeDetailHeaderBottomWrap = styled.div`
  font-size: 14px;
  padding: 15px;
  display: flex;
  justify-content: end;
  color: ${colors.gray030};
`

const NoticeDetailContentWrap = styled.div`
  padding: 15px;
  min-height: 500px;
  border-bottom: 1px solid ${colors.gray010};
`
