import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";

export const useFindPopups = () => {
    const api = useCallback(() => {
        return axios.post(`/vendor/api/v1/popup/`);
    }, []);

    return useQuery(
        ['popup'],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
