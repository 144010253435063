import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {useFindPlan} from "../../../api/plan";

export const PlanTemplate = () => {
    const {data} = useFindPlan();

    return (
        <LayoutTemplate>
            <PlanWrap>
                {
                    data && data[0] && <div dangerouslySetInnerHTML={{__html: data[0].content}} />
                }
            </PlanWrap>
        </LayoutTemplate>
    );
}

const PlanWrap = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  gap: 80px;
`
