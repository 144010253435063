import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {useFindIntroduce} from "../../../api/introduce";

export const IntroduceTemplate = () => {
    const {data} = useFindIntroduce();

    return (
        <LayoutTemplate>
            <IntroduceWrap>
                {
                    data && data[0] && <div dangerouslySetInnerHTML={{__html: data[0].content}} />
                }
            </IntroduceWrap>
        </LayoutTemplate>
    );
}

const IntroduceWrap = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  gap: 80px;
`
