import styled from "styled-components";
import {ChangeEvent, ReactNode} from "react";
import Input from "../../atoms/input";
import {colors} from "../../../styles";
import {shapes, sizes} from "../../../types";

type props = {
    id?: string;
    name?: string;
    width?: string;
    accept?: string;
    children?: ReactNode;
    theme?: buttonFileThemes;
    size?: sizes;
    shape?: shapes;
    onInput?(e: ChangeEvent<HTMLInputElement>): void;
}

export const buttonFileThemes = {
    normal: 'normal',
    black: 'black',
    border: 'border'
} as const;
export type buttonFileThemes = typeof buttonFileThemes[keyof typeof buttonFileThemes];

const ButtonFile = ({id, onInput, name, accept, width, size, theme, shape, children}: props) => {
    return (
        <InputFileWrap width={width}>
            <SizeButtonFileWrap htmlFor={id} size={size} theme={theme} shape={shape}>{children}</SizeButtonFileWrap>
            <InputWrap type={'file'} name={name} id={id} onInput={onInput} accept={accept}/>
        </InputFileWrap>
    )
}

const InputFileWrap = styled.div<{ width?: string }>`
  ${({width}) => (width ? `width: ${width}` : '')};
  display: flex;
`

const InputWrap = styled.input`
  display: none;
`

const LabelWrap = styled.label<props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.black};
  color: ${colors.white};
`

const ThemeButtonFileWrap = styled(LabelWrap)`
  ${({theme}) => {
    switch (theme) {
        case buttonFileThemes.black:
            return `
            color: ${colors.white};
            background-color: ${colors.black};
          `;
        case buttonFileThemes.border:
            return `
            color: ${colors.black};
            background-color: #fff;
            border: 1px solid ${colors.black};
          `;
        default:
            return `
            color: ${colors.white};
            background-color: ${colors.red};
        `;
    }
}};
`

const ShapeButtonFileWrap = styled(ThemeButtonFileWrap)`
  ${({shape}) => {
    switch (shape) {
        case shapes.circle:
            return `
            border-radius: 50%;
          `;
        case shapes.rectangle:
            return `
            border-radius: 16px;
          `;
        default:
            return `
            border-radius: 8px;
        `;
    }
}};
`

const SizeButtonFileWrap = styled(ShapeButtonFileWrap)`
  ${({size}) => {
    switch (size) {
        case sizes.xs:
            return `
            padding: 6px;
          `
        case sizes.sm:
            return `
            padding: 10px;
          `
        case sizes.md:
            return `
            padding: 14px;
          `
        case sizes.lg:
            return `
            padding: 18px;
          `
        default:
            return `
            padding: 10px;
        `;
    }
}};
`

export default ButtonFile;
