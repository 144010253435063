import styled from "styled-components";
import {FindPasswordTemplate} from "../../components/templates/find/findPw";

export const FindPw = () => {
    return (
        <FindIdWrap>
            <FindPasswordTemplate />
        </FindIdWrap>
    )
}

const FindIdWrap = styled.div`

`
