import styled from "styled-components";
import {NoticeTemplate} from "../../components/templates/notice";

export const Notice = () => {
    return (
        <NoticeWrap>
            <NoticeTemplate />
        </NoticeWrap>
    )
}

const NoticeWrap = styled.div`

`
