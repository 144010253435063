import styled from "styled-components";
import {ReactNode} from "react";
import {colors} from "../../../styles";
import {Spinner1} from "../../../styles/component";

type props = {
    columns: columnsType[],
    data: any[],
    children?: ReactNode,
    isLoading?: boolean,
    onClick?(value: any): void
}

type columnsType = {
    header: string,
    accessor: string,
    width: string
}
const Table = ({columns, data, isLoading, onClick}: props) => {
    return (
        <TableItemWrap>
            <TableHeaderWrap>
                <tr>
                    {
                        columns.map((i, index) => {
                            return <TableHeaderItem key={index} dangerouslySetInnerHTML={{__html: i.header}}></TableHeaderItem>
                        })
                    }
                </tr>
            </TableHeaderWrap>

            {
                !isLoading ?
                    <TableContentsWrap>
                        {
                            data.length > 0 ? data.map((i, index) => {
                                    return (
                                        <TableContentWrap key={index} $isClick={!!onClick}>
                                            {
                                                columns.map((j, index) => (
                                                    <TableContentItem width={j.width} key={index}
                                                                      onClick={() => onClick && onClick(i)}>{i[columns[index].accessor]}</TableContentItem>
                                                ))
                                            }
                                        </TableContentWrap>
                                    );
                                }) :
                                <TableContentWrap $isClick={!!onClick}>
                                    <TableContentItem colSpan={columns.length} width={'100%'}>게시물이 존재하지 않습니다.</TableContentItem>
                                </TableContentWrap>
                        }
                    </TableContentsWrap>
                    :
                    <TableContentsWrap>
                        <tr>
                            <TableContentItem colSpan={columns.length} width={'100%'}>
                                <Spinner1/>
                            </TableContentItem>
                        </tr>
                    </TableContentsWrap>
            }
        </TableItemWrap>
    )
}

const TableItemWrap = styled.table`
  font-size: 14px;
  flex: 1;
  white-space: nowrap;
  border-collapse: collapse;
`

const TableHeaderWrap = styled.thead`
  align-items: center;
  text-align: center;
  color: #4e4e51;
  font-weight: 500;
  background-color: ${colors.gray005};
  border-top: 2px solid ${colors.red};
`

const TableHeaderItem = styled.th`
  padding: 15px 5px;
  font-weight: 500;
`

const TableContentsWrap = styled.tbody`
  flex-direction: column;
  text-align: center;
`

const TableContentWrap = styled.tr<{ $isClick?: boolean }>`
  align-items: center;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  border-radius: 6px;

  ${({$isClick}) => $isClick && `
  cursor: pointer;
  
  &:hover {
    background-color: #f7f7f8;
  } 
  `}
}
`

const TableContentItem = styled.td`
  padding: 15px 5px;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;
  }
`

export default Table;
