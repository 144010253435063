import styled from "styled-components";
import {useFindPopups} from "../../../api/popup";
import {useCallback, useEffect, useRef, useState} from "react";
import {FlexWrap} from "../../../styles/component";
import Button, {buttonThemes} from "../button";
import {shapes, sizes} from "../../../types";
import Slider from "react-slick";
import {popupTypes} from "../../../types/popup";
import "slick-carousel/slick/slick.css"

export const Popup = () => {
    const {data: popups} = useFindPopups();
    const slickRef = useRef<Slider>(null);
    const [close, setClose] = useState(false);

    const onCloseDay = useCallback(() => {
        const date = new Date();
        localStorage.setItem('simplecn_popup', date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000).toString());
        setClose(true);
    }, []);

    const onClose = useCallback(() => {
        const date = new Date();
        localStorage.setItem('simplecn_popup', date.setTime(date.getTime() + 1 * 1 * 60 * 60 * 1000).toString());
        setClose(true);
    }, []);

    useEffect(() => {
        const closeDate = localStorage.getItem('simplecn_popup');

        if (closeDate) {
            const date = new Date();
            if (Number(closeDate) > date.getTime()) setClose(true);
        }
    }, []);

    const onPrevious = useCallback(() => slickRef.current && slickRef.current.slickPrev(), []);
    const onNext = useCallback(() => slickRef.current && slickRef.current.slickNext(), []);

    if (popups && !close) return (
        <PopupWrap>
            <PopupInnerWrap>
                <PopupSlideWrap>
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" fill="none"
                         onClick={onPrevious}
                         stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m15 18-6-6 6-6"></path>
                    </svg>
                    {
                        popups && popups.data && (
                            <Slider arrows={false} dots={false} ref={slickRef} useTransform={false}>
                                {
                                    popups.data.map((el: popupTypes, index: number) => (
                                        <img src={el.popupFile} alt={'popup'} key={index}/>
                                    ))
                                }
                            </Slider>
                        )
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" fill="none"
                         onClick={onNext}
                         stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m9 18 6-6-6-6"></path>
                    </svg>
                </PopupSlideWrap>

                <FlexWrap $width={100}>
                    <Button size={sizes.md} theme={buttonThemes.white} shape={shapes.rectangle} width={'100%'} onClick={onCloseDay}>하루 동안 보지
                        않기</Button>
                    <Button size={sizes.md} theme={buttonThemes.white} shape={shapes.rectangle} width={'100%'} onClick={onClose}>닫기</Button>
                </FlexWrap>
            </PopupInnerWrap>
        </PopupWrap>
    )
    else return <></>
}

const PopupWrap = styled.div`
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 40px;
`;

const PopupInnerWrap = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 400px;

  & > svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  & > img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  & button {
    font-size: 14px;
  }
`

const PopupSlideWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & svg {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 36px);
    z-index: 1;
  }

  & svg:first-child {
    left: 0;
  }

  & svg:last-child {
    right: 0;
  }

  .slick-dots li.slick-active button:before {
    color: #2E9FFF;
  }

  & img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
`
