import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Input from "../../atoms/input";
import {colors} from "../../../styles";
import Button, {buttonThemes} from "../../atoms/button";
import {useRecoilValue} from "recoil";
import {userState} from "../../../recoil/user";
import {useCallback, useEffect, useState} from "react";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {ResponsiveWrap} from "../../molecules/responsiveWrap";
import {useFindInvoices, useInsertInvoice} from "../../../api/invoice";
import InputFile from "../../molecules/inputFile";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FlexWrap} from "../../../styles/component";

export const InvoiceWriteTemplate = () => {
    const navigate = useNavigate();
    const {data: invoices} = useFindInvoices();
    const {mutate} = useInsertInvoice();
    const {t} = useTranslation();
    const {nameKr, nameEng, bizKrName, bizEngAddress, bizEngName, bizNumber, telephone} = useRecoilValue(userState);
    const [info, setInfo] = useState<Record<string, any>>({
        bizEngName: {value: '', require: true, validate: false, pattern: false},
        bizKrName: {value: '', require: true, validate: false, pattern: false},
        bizEngAddress: {value: '', require: true, validate: false, pattern: false},
        bizEngCeo: {value: '', require: true, validate: false, pattern: false},
        bizKrCeo: {value: '', require: true, validate: false, pattern: false},
        bizNumber: {value: '', require: true, validate: false, pattern: false},
        bizTelephone: {value: '', require: true, validate: false, pattern: false},
        price: {value: '', require: true, validate: false, pattern: false},
        items: {value: [{name: '', amount: '', price: ''}], require: true, validate: false, pattern: false},
        chinaAccountNumber: {value: '', require: true, validate: false, pattern: false},
        chinaAccountBank: {value: '', require: true, validate: false, pattern: false},
        chinaAccountOwner: {value: '', require: true, validate: false, pattern: false},
        chinaAccountFile: {value: '', require: true, validate: false},
        jeungbingFile: {value: '', require: false, validate: true},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onTotalPrice = useCallback(() => {
        if (info.items && info.items.value.length > 0) {
            const result = info.items.value.map(({amount, price}: {
                amount: number,
                price: number
            }) => (amount ? amount : 0) * (price ? price : 1)).reduce((sum: number, curr: number) => sum + curr);
            return result ? result.toLocaleString() : 0;
        } else {
            return 0;
        }
    }, [info.items]);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        if (Number(onTotalPrice().replaceAll(',', '')) < 1000) {
            alert('최소 신청 금액은 1,000 USD 입니다.')
            return;
        }

        if (Number(onTotalPrice().replaceAll(',', '')) !== Number(info.price)) {
            alert('신청 금액과 품목 총 가격이 동일해야 합니다.')
            return;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(onTransferData()));
        if (info.chinaAccountFile) formData.append('chinaAccountFile', info.chinaAccountFile);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/invoice');
            },
        });
    }, [info]);

    useEffect(() => {
        if (invoices && invoices.data.length > 0) {
            const invoice = invoices?.data[invoices?.data?.length - 1]?.jeungbingFile;

            if (!invoice) {
                alert(t('invoice.itemStrong'))
                navigate('/invoice')
                return;
            }
        }
    }, [invoices]);

    useEffect(() => {
        info.bizKrCeo = {value: nameKr, require: true, validate: true, pattern: false};
        info.bizEngCeo = {value: nameEng, require: true, validate: true, pattern: false};
        info.bizKrName = {value: bizKrName, require: true, validate: true, pattern: false};
        info.bizEngName = {value: bizEngName, require: true, validate: true, pattern: false};
        info.bizEngAddress = {value: bizEngAddress, require: true, validate: true, pattern: false};
        info.bizNumber = {value: bizNumber, require: true, validate: true, pattern: false};
        info.bizTelephone = {value: telephone, require: true, validate: true, pattern: false};
        setInfo(info);
    }, [nameKr, nameEng, bizKrName, bizEngName, bizEngAddress, bizNumber, telephone]);

    return (
        <LayoutTemplate>
            <InvoiceWriteWrap>
                <InvoiceItemWrap>
                    <strong>
                        {t('invoice.itemStrong')}
                    </strong>

                    <InvoiceUploadWrap>
                        <Button width={'150px'} theme={buttonThemes.black} onClick={() => navigate('/invoice')}>
                            {t('invoice.jeungbingFileUpload')}
                        </Button>
                        <FlexWrap $gap={5} onClick={() => navigate('/notice/49')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <circle cx="12" cy="12" r="10"/>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
                                <path d="M12 17h.01"/>
                            </svg>
                            수입증빙 자료란?
                        </FlexWrap>
                    </InvoiceUploadWrap>
                </InvoiceItemWrap>

                <InvoiceItemsWrap>
                    <InvoiceItemWrap>
                        <span>{t('invoice.bizEngName')}</span>
                        <Input placeholder={'사업자 상호명 (영문)'} width={'150px'} name={'bizEngName'} value={info.bizEngName.value}
                               onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.bizKrName')}</span>
                        <Input placeholder={'사업자 상호명 (한글)'} width={'150px'} name={'bizKrName'} value={info.bizKrName.value} onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.bizEngAddress')}</span>
                        <Input placeholder={'사업자 주소 (영문)'} width={'150px'} name={'bizEngAddress'} value={info.bizEngAddress.value}
                               onChange={onChangeInfo}/>
                    </InvoiceItemWrap>
                </InvoiceItemsWrap>

                <InvoiceItemsWrap>
                    <InvoiceItemWrap>
                        <span>{t('invoice.bizEngName')}</span>
                        <Input placeholder={'대표자명 (영문)'} width={'150px'} name={'bizEngCeo'} value={info.bizEngCeo.value} onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.bizKrName')}</span>
                        <Input placeholder={'대표자명 (한글)'} width={'150px'} name={'bizKrCeo'} value={info.bizKrCeo.value} onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.bizNumber')}</span>
                        <Input placeholder={'- 없이 숫자만'} width={'150px'} name={'bizNumber'} value={info.bizNumber.value} onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.telephone')}</span>
                        <Input type={'number'} placeholder={'1012341234'} width={'150px'} name={'bizTelephone'} value={info.bizTelephone.value}
                               onChange={onChangeInfo}/>
                    </InvoiceItemWrap>
                </InvoiceItemsWrap>

                <hr/>

                <InvoiceItemsWrap>
                    <InvoiceItemWrap>
                        <span>{t('invoice.ApplicationAmount')}</span>
                        <Input type={'number'} placeholder={t('invoice.ApplicationAmountPlaceholder')} width={'150px'} name={'price'}
                               value={info.price.value}
                               onChange={onChangeInfo}/>
                    </InvoiceItemWrap>
                </InvoiceItemsWrap>

                <InvoiceItemsWrap>
                    <InvoiceItemWrap>
                        <ResponsiveWrap title={t('invoice.importItems')} name={'items'} value={info.items.value} setter={setInfo} maxLength={8}>
                            <span>{t('invoice.item')}</span>
                            <Input placeholder={t('invoice.name')} name={'name'}/>
                            <Input type={'number'} placeholder={t('invoice.amount')} name={'amount'}/>
                            <Input type={'number'} placeholder={t('invoice.Price')} name={'price'}/>
                        </ResponsiveWrap>
                    </InvoiceItemWrap>
                </InvoiceItemsWrap>

                <InvoiceTotalWrap>
                    <h3>Total ($): {onTotalPrice()}</h3>
                    <span>{t('invoice.chinaAndName')}</span>
                </InvoiceTotalWrap>

                <hr/>

                <InvoiceItemsWrap>
                    <InvoiceItemWrap>
                        <span>{t('invoice.chinaAccountNumber')}</span>
                        <Input placeholder={t('invoice.chinaAccountNumber')} width={'150px'} name={'chinaAccountNumber'}
                               value={info.chinaAccountNumber.value}
                               onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.chinaAccountBank')}</span>
                        <Input placeholder={t('invoice.chinaAccountBank')} width={'150px'} name={'chinaAccountBank'}
                               value={info.chinaAccountBank.value}
                               onChange={onChangeInfo}/>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.chinaAccountOwner')}</span>
                        <Input placeholder={t('invoice.chinaAccountOwner')} width={'150px'} name={'chinaAccountOwner'}
                               value={info.chinaAccountOwner.value}
                               onChange={onChangeInfo}/>
                        <span>{t('invoice.chinaAndName')}</span>
                    </InvoiceItemWrap>

                    <InvoiceItemWrap>
                        <span>{t('invoice.chinaAccountFileAttach')}</span>
                        <InputFile htmlFor={'chinaAccountFile'} name={'chinaAccountFile'} accept={'image/*'} width={'250px'}
                                   fileName={info.chinaAccountFile?.value?.name && info.chinaAccountFile?.value?.name}
                                   onInput={onChangeInfo}>{t('invoice.attach')}</InputFile>
                    </InvoiceItemWrap>
                </InvoiceItemsWrap>

                <InvoiceItemsWrap>
                    <Button width={'100px'} $isAble={onValidate()} onClick={onSubmit}>
                        {t('invoice.submit')}
                    </Button>
                </InvoiceItemsWrap>
            </InvoiceWriteWrap>
        </LayoutTemplate>
    )
}

const InvoiceWriteWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  gap: 30px;
`

const InvoiceUploadWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  & > div {
    align-items: center;
    cursor: pointer;
  }
`

const InvoiceItemsWrap = styled.div`
  display: flex;
  gap: 30px;
  font-size: 14px;
`

const InvoiceItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
  font-weight: bold;

  & > strong {
    font-weight: normal;
    color: ${colors.red};
  }
`

const InvoiceTotalWrap = styled.div`
  line-height: 25px;

  & > span {
    color: ${colors.red};
    font-size: 14px;
  }
`;
