import styled from 'styled-components';
import {colors, fontStyles, typography} from "../../../styles";
import {ChangeEvent, useCallback} from "react";
import {shapes, sizes} from "../../../types";

type props = {
    width?: string;
    theme?: inputThemes;
    shape?: shapes;
    size?: sizes;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    value?: any;
    id?: string;
    name?: string;
    flex?: number;
    fontStyle?: fontStyles;
    onChange?(e?:ChangeEvent<HTMLInputElement>): void;
    onInput?(): void;
}

export const inputThemes = {
    normal: 'normal',
    gray: 'gray',
    plain: 'plain'
} as const;
export type inputThemes = typeof inputThemes[keyof typeof inputThemes];

const Input = ({type, width, theme, shape, size, placeholder, fontStyle, flex, value, id, name, disabled, onChange, onInput}: props) => {

    const onAfterChange = useCallback((e:ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
            e.target.value = e.target.value.replace(/[^0-9\.]/g, '');
        }

        if (onChange) onChange(e);
    }, [onChange]);

    return (
        <SizeInputWrap
            type={type}
            width={width}
            theme={theme}
            shape={shape}
            size={size}
            value={value}
            disabled={disabled}
            id={id}
            name={name}
            placeholder={placeholder}
            flex={flex}
            onChange={onAfterChange}
            onInput={onInput}
            fontStyle={fontStyle}
        />
    )
};

const InputWrap = styled.input<props>`
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')};
  ${({flex}) => (flex ? `flex: ${flex}` : '')};
  ${({width}) => (width ? `width: ${width}` : '')};
  transition: all 0.3s;
`;

const ThemeInputWrap = styled(InputWrap)`
  ${({theme}) => {
    switch (theme) {
      case inputThemes.gray:
        return `
            border: 1px solid ${colors.gray010};
            background-color: ${colors.gray002};
            
            &::placeholder {
                color: ${colors.black};
            }
            
            &:focus {
                border: 1px solid ${colors.black};
            }
          `
      case inputThemes.plain:
        return `
            color: ${colors.black};
            border: 1px solid ${colors.black};
            
            &::placeholder {
                color: ${colors.gray};
            }
            
            &:focus {
                border: 1px solid ${colors.main};
            }
        `;
      default:
        return `
            border: 1px solid ${colors.gray010};
            background-color: ${colors.white};
            
            &::placeholder {
                color: ${colors.gray030};
            }
            
            &:focus {
                border: 1px solid ${colors.black};
            }
        `;
    }
  }};
`

const ShapeInputWrap = styled(ThemeInputWrap)`
  ${({shape}) => {
    switch (shape) {
      case shapes.rectangle:
        return `
            border-radius: 8px;
            padding: 0 15px;
          `
      default:
        return `
            border-radius: 4px;
            padding: 0 15px;
        `;
    }
  }};
`

const SizeInputWrap = styled(ShapeInputWrap)`
  ${({size}) => {
    switch (size) {
      case sizes.sm:
        return `
            height: 40px;
          `
      default:
        return `
            height: 40px;
        `;
    }
  }};
`

export default Input;
