import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {introduceTypes} from "../types/introduce";

export const useFindPlan = () => {
    const api = useCallback(() => {
        return axios.get(`/vendor/api/v1/plan/`);
    }, []);

    return useQuery(
        ['plan'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}
