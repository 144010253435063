import styled from "styled-components";
import {colors} from "../../../styles";
import {ReactNode, useCallback, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import korea from "../../../assets/images/korea.png";
import usa from "../../../assets/images/usa.png";
import {userState} from "../../../recoil/user";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {modalState, showModal} from "../../../recoil/modal";
import {useFindPrivacy} from "../../../api/privacy";
import SelectLang from "../../atoms/selectLang";
import {useTranslation} from "react-i18next";

export const LayoutTemplate = ({children}: { children: ReactNode }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const {mutate} = useFindPrivacy();
    const {seq, email} = useRecoilValue(userState);
    const setModal = useSetRecoilState(modalState);

    const onPrivacy1Modal = useCallback(() => {
        mutate({type: 1}, {
            onSuccess: ({data}) => {
                showModal(setModal, {title: '이용약관', children: <div dangerouslySetInnerHTML={{__html: data.content}}></div>});
            }
        });
    }, []);

    const onPrivacy2Modal = useCallback(() => {
        mutate({type: 2}, {
            onSuccess: ({data}) => {
                showModal(setModal, {title: '개인정보 처리방침', children: <div dangerouslySetInnerHTML={{__html: data.content}}></div>});
            }
        });
    }, []);

    return (
        <LayoutWrap>
            <LayoutHeaderWrap>
                <LayoutHeaderMainWrap>
                    <Logo src={logo} alt={'logo'} onClick={() => navigate('/')}/>

                    <LayoutHeaderCenterWrap>
                        <LayoutHeaderCenterItemWrap onClick={() => navigate('/introduce')}
                                                    $active={location.pathname === '/introduce'}>{t('header.introduce')}</LayoutHeaderCenterItemWrap>
                        <LayoutHeaderCenterItemWrap onClick={() => navigate('/help')}
                                                    $active={location.pathname === '/help'}>{t('header.help')}</LayoutHeaderCenterItemWrap>
                        <LayoutHeaderCenterItemWrap onClick={() => navigate('/plan')}
                                                    $active={location.pathname === '/plan'}>{t('header.plan')}</LayoutHeaderCenterItemWrap>
                        <LayoutHeaderCenterItemWrap $active={location.pathname.indexOf('invoice') !== -1} $hover={true}>
                            {t('header.invoice')}

                            <LayoutHeaderCenterHoverWrap>
                                <LayoutHeaderCenterHoverItemWrap onClick={() => seq ? navigate('/invoice/write') : navigate('/login')}>
                                    {t('header.invoiceWrite')}
                                </LayoutHeaderCenterHoverItemWrap>
                                <LayoutHeaderCenterHoverItemWrap onClick={() => seq ? navigate('/invoice') : navigate('/login')}>
                                    {t('header.invoiceList')}
                                </LayoutHeaderCenterHoverItemWrap>
                            </LayoutHeaderCenterHoverWrap>
                        </LayoutHeaderCenterItemWrap>
                        <LayoutHeaderCenterItemWrap onClick={() => navigate('/notice')}
                                                    $active={location.pathname.indexOf('notice') !== -1}>{t('header.notice')}</LayoutHeaderCenterItemWrap>
                        <LayoutHeaderCenterItemWrap onClick={() => navigate('/qna')}
                                                    $active={location.pathname === '/qna'}>{t('header.qna')}</LayoutHeaderCenterItemWrap>
                    </LayoutHeaderCenterWrap>

                    <LayoutHeaderRightWrap>
                        <LayoutHeaderTranslateWrap>
                            <SelectLang items={[{text: <><img src={korea} alt={'korea'}/><span>한국어</span></>, value: '한국어'}, {text: <><img src={usa} alt={'usa'}/><span>영어</span></>, value: '영어'}]}/>
                        </LayoutHeaderTranslateWrap>

                        <LayoutHeaderSignWrap>
                            <li onClick={() => navigate(seq ? '/mypage' : '/login')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                     strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user">
                                    <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/>
                                    <circle cx="12" cy="7" r="4"/>
                                </svg>
                            </li>
                        </LayoutHeaderSignWrap>
                    </LayoutHeaderRightWrap>
                </LayoutHeaderMainWrap>
            </LayoutHeaderWrap>

            <LayoutContentWrap>
                {children}
            </LayoutContentWrap>

            <LayoutFooterWrap>
                <LayoutFooterLeftWrap>
                    <img src={logo} alt={'logo'} />
                </LayoutFooterLeftWrap>

                <LayoutFooterMiddleWrap>
                    <ul>
                        <span onClick={onPrivacy1Modal}>{t('footer.privacy1')}</span>
                        <li>|</li>
                        <span onClick={onPrivacy2Modal}>{t('footer.privacy2')}</span>
                    </ul>

                    {/*<h2>회사정보</h2>*/}

                    {/*<ul>*/}
                    {/*    <li>회사명 | 주식회사</li>*/}
                    {/*    <li>대표명 | 홍길동</li>*/}
                    {/*    <li>사업자번호 | 123-45-67890</li>*/}
                    {/*</ul>*/}
                    {/*<ul>*/}
                    {/*    <li>주소 | 서울특별시 서초구 서초대로</li>*/}
                    {/*    <li>이메일 | abc123@gmail.com</li>*/}
                    {/*</ul>*/}

                    <ul>
                        <li>Copyright © SIMPLE CHINA All Rights Reserved.</li>
                    </ul>
                </LayoutFooterMiddleWrap>
                <LayoutFooterRightWrap>
                    <ul>
                        {/*<li>고객센터</li>*/}
                        {/*<li>1200-1234</li>*/}
                        <li>{t('footer.timeDesc1')}</li>
                        <li>{t('footer.timeDesc2')}</li>
                        <li>{t('footer.timeDesc3')}</li>
                        <li>{t('footer.timeDesc4')}</li>
                    </ul>
                </LayoutFooterRightWrap>
            </LayoutFooterWrap>
        </LayoutWrap>
    )
};

const LayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  max-width: 150px;
  cursor: pointer;
`

const LayoutHeaderWrap = styled.div`
  box-shadow: rgb(0 0 0 / 19%) 0 3px 4px 0;
`

const LayoutHeaderMainWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px 40px;
  justify-content: space-between;
  align-items: center;
`

const LayoutHeaderCenterWrap = styled.ul`
  display: flex;
  gap: 50px;
  font-weight: 500;

  & li {
    cursor: pointer;
  }
`

const LayoutHeaderCenterItemWrap = styled.li<{ $active?: boolean, $hover?: boolean }>`
  color: ${({$active}) => $active && colors.red};
  border-bottom: ${({$active}) => $active && `2px solid ${colors.red}`};
  padding: 5px 0;
  position: relative;
  
  ${({$hover}) => $hover && `
    &:hover > div {
        display: flex;
    } 
  `}}
`

const LayoutHeaderCenterHoverWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  left: -25%;
  top: 28px;
  background-color: ${colors.white};
  width: 150%;
  position: absolute;
  z-index: 10;
  color: ${colors.black};
  box-shadow: rgb(0 0 0 / 19%) 0 2px 4px 0;
  padding: 10px;
  font-size: 14px;
`

const LayoutHeaderCenterHoverItemWrap = styled.div`
`

const LayoutHeaderRightWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
`

const LayoutHeaderSignWrap = styled.ul`
  display: flex;
  gap: 30px;

  & li {
    cursor: pointer;
  }
`

const LayoutHeaderTranslateWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 40px;
  }
`

const LayoutContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1000px;
`


const LayoutFooterWrap = styled.div`
  background-color: ${colors.gray005};
  color: ${colors.gray080};
  display: flex;
  padding: 40px;
  font-size: 14px;
`

const LayoutFooterLeftWrap = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > img {
    width: 100%;
    max-width: 200px;
  }
`

const LayoutFooterMiddleWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;

  & ul {
    display: flex;
    gap: 10px;
    
  }
  
  & ul span { 
    cursor: pointer;
  }

  //& > ul:nth-child(2) {
  //  color: #fff;
  //}

  & > h2 {
    padding-top: 10px;
  }

  & > ul:last-child {
    padding-top: 10px;
  }
`

const LayoutFooterRightWrap = styled.div`
  width: 30%;

  & ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  //& ul > li:nth-child(2) {
  //  font-size: 28px;
  //  font-weight: bold;
  //  padding: 4px 0;
  //}
`
