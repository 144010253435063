import styled from "styled-components";
import {QnaDetailTemplate} from "../../components/templates/qna/detail";

export const QnaDetail = () => {
    return (
        <QnaWrap>
            <QnaDetailTemplate />
        </QnaWrap>
    )
}

const QnaWrap = styled.div`

`
