import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {colors} from "../../../styles";
import {useParams} from "react-router-dom";
import {useFindQna} from "../../../api/qna";

export const QnaDetailTemplate = () => {
    const {seq} = useParams();
    const {data: qna} = useFindQna({seq});

    return (
        <LayoutTemplate>
            {
                qna && qna.seq &&
                <QnaDetailWrap>
                    <QnaDetailHeaderTopWrap>
                        <h3>{qna.title}</h3>
                        <span>{qna.insertDate}</span>
                    </QnaDetailHeaderTopWrap>

                    <QnaDetailHeaderMiddleWrap>
                        <span>{qna.nameKr}</span>
                    </QnaDetailHeaderMiddleWrap>

                    <QnaDetailContentWrap dangerouslySetInnerHTML={{__html: qna.content}} />
                    <QnaDetailAnswerWrap dangerouslySetInnerHTML={{__html: qna.answer}} />
                </QnaDetailWrap>
            }
        </LayoutTemplate>
    )
}

const QnaDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
`

const QnaDetailHeaderTopWrap = styled.div`
  background-color: ${colors.gray005};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid ${colors.red};

  & > span {
    color: ${colors.gray030};
    font-size: 14px;
  }
`

const QnaDetailHeaderMiddleWrap = styled.div`
  font-size: 14px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray010};

  & > span:last-child {
    color: ${colors.gray030};
  }
`

const QnaDetailContentWrap = styled.div`
  padding: 15px;
  min-height: 300px;
  border-bottom: 1px solid ${colors.gray010};
`

const QnaDetailAnswerWrap = styled.div`
  padding: 15px;
  min-height: 300px;
  border-bottom: 1px solid ${colors.gray010};
`
