import styled from "styled-components";
import Button, {buttonThemes} from "../../atoms/button";
import {useNavigate} from "react-router-dom";
import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import {useSendEmailCode, useUserFindPw} from "../../../api/user";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {Danger} from "../../../styles/component";
import {useTranslation} from "react-i18next";
import {sizes} from "../../../types";
import {LayoutTemplate} from "../layout";

export const FindPasswordTemplate = () => {
    const navigate = useNavigate();
    const {mutate, isLoading: sendEmailCodeLoading} = useSendEmailCode();
    const {mutate: userFindPwMutate, isLoading: userFindPwLoading} = useUserFindPw();
    const {t} = useTranslation();
    const [step, setStep] = useState(false);
    const [info, setInfo] = useState<Record<string, any>>({
        email: {value: '', require: true, validate: false, pattern: regex.email},
        code: {value: '', require: true, validate: false},
        password: {value: '', require: true, validate: false, pattern: regex.password},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onAuthSend = useCallback(() => {
        const info = onTransferData();
        mutate(info, {
            onSuccess: async () => {
                setStep(true);
            }
        })
    }, [info]);

    const onFindPw = useCallback(() => {
        const info = onTransferData();
        if (!info.code) {
            alert(t('signup.codeCheck'));
            return false;
        }

        userFindPwMutate(info, {
            onSuccess: async () => {
                alert(t('signup.passwordSubmit'))
                return navigate('/login')
            },
        });
    }, [info]);

    return (
        <LayoutTemplate>
            <FindWrap>
                <FindItem>
                    <strong> {t('signup.findPw')} </strong>
                    <Input type={"text"} value={info.email.value} name={'email'} onChange={onChangeInfo} placeholder={t('signup.email')} disabled={step}/>
                    {(!info.email.validate && info.email.value) && <Danger>{t('signup.emailDanger')}</Danger>}
                    {
                        step && (
                            <>
                                <Input type={"text"} value={info.code.value} name={'code'} onChange={onChangeInfo} placeholder={t('signup.code')}/>
                                <Input type={"password"} placeholder={t('signup.passwordDesc')} name={'password'} value={info.password.value}
                                       onChange={onChangeInfo}/>
                                {(!info.password.validate && info.password.value) && <Danger>{t('signup.passwordDanger')}</Danger>}
                            </>
                        )
                    }
                    <Button size={sizes.md} onClick={() => (step ? onFindPw() : onAuthSend())} $isAble={!step ? info.email.validate : onValidate()} $isLoading={!step ? !sendEmailCodeLoading : !userFindPwLoading}>{t('signup.submit')}</Button>
                </FindItem>
            </FindWrap>
        </LayoutTemplate>
    )
        ;
}

const FindWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  margin: 10vw auto;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  background-color: #fff;
`

const FindItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > button {
    font-size: 14px;
  }
`
