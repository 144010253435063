import styled from "styled-components";
import {PlanTemplate} from "../../components/templates/plan";

export const Plan = () => {
    return (
        <PlanWrap>
            <PlanTemplate />
        </PlanWrap>
    )
}

const PlanWrap = styled.div`

`
