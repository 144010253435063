import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import {useEffect} from "react";
import {Home} from "../home";
import {Introduce} from "../introduce";
import {Notice} from "../notice";
import {Help} from "../help";
import {Invoice} from "../invoice";
import {InvoiceWrite} from "../invoice/write";
import {Login} from "../login";
import {Signup} from "../signup";
import {SignupComp} from "../signup/complete";
import {useLoginCheck} from "../../api/user";
import {useSetRecoilState} from "recoil";
import {deleteUser, setUser, userState} from "../../recoil/user";
import {NoticeDetail} from "../notice/detail";
import {MyPage} from "../mypage";
import {Plan} from "../plan";
import {Qna} from "../qna";
import {QnaDetail} from "../qna/detail";
import {QnaWrite} from "../qna/write";
import {FindIdAndPw} from "../find";
import {FindId} from "../find/findId";
import {FindPw} from "../find/findPw";
import {FindIdAndPassword} from "../../components/templates/find";

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {mutate} = useLoginCheck();
    const userStateHook = useSetRecoilState(userState);

    useEffect(() => {
        if (location.pathname === '/login') return;

        mutate(undefined, {
            onSuccess: async ({data}) => {
                setUser(userStateHook, {
                    seq: data.seq,
                    email: data.email,
                    nameKr: data.nameKr,
                    nameEng: data.nameEng,
                    bizKrName: data.bizKrName,
                    bizEngName: data.bizEngName,
                    bizEngAddress: data.bizEngAddress,
                    bizNumber: data.bizNumber,
                    telephone: data.telephone,
                    bizFile: data.bizFile,
                    passportFile: data.passportFile,
                });
            },
            onError: async () => {
                deleteUser(userStateHook);
                if (location.pathname === '/invoice' || location.pathname === '/mypage') navigate('/login');
            }
        });
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/introduce" element={<Introduce/>}/>
            <Route path="/plan" element={<Plan/>}/>
            <Route path="/qna" element={<Qna/>}/>
            <Route path="/qna/:seq" element={<QnaDetail/>}/>
            <Route path="/qna/write" element={<QnaWrite/>}/>
            <Route path="/notice" element={<Notice/>}/>
            <Route path="/notice/:seq" element={<NoticeDetail/>}/>
            <Route path="/help" element={<Help/>}/>
            <Route path="/mypage" element={<MyPage/>}/>
            <Route path="/invoice" element={<Invoice/>}/>
            <Route path="/invoice/write" element={<InvoiceWrite/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/signup/complete" element={<SignupComp/>}/>
            <Route path="/find" element={<FindIdAndPassword/>}/>
            <Route path="/find/pw" element={<FindPw/>}/>
        </Routes>
    );
}
