import styled from "styled-components";
import {ChangeEvent, ReactNode} from "react";
import Input, {inputThemes} from "../../atoms/input";
import {shapes, sizes} from "../../../types";

type props = {
    width?: string;
    theme?: inputThemes;
    shape?: shapes;
    size?: sizes;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    value?: any;
    id?: string;
    name?: string;
    children?: ReactNode;
    direction?: directions;
    onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

export const directions = {
    left: 'left',
    right: 'right',
} as const;
export type directions = typeof directions[keyof typeof directions];

const InputChildren = ({type, width, theme, shape, size, placeholder, value, id, name, disabled, onChange, direction, children}: props) => {
    return (
        <InputChildrenWrap>
            {direction === directions.left &&
                <InputChildrenItem>
                    {children}
                </InputChildrenItem>
            }
            <Input name={name}
                   onChange={onChange}
                   type={type}
                   width={width}
                   theme={theme}
                   shape={shape}
                   size={size}
                   placeholder={placeholder}
                   value={value}
                   id={id}
                   disabled={disabled}
            />
            {direction === directions.right &&
                <InputChildrenItem>
                    {children}
                </InputChildrenItem>
            }
        </InputChildrenWrap>
    )
}

const InputChildrenWrap = styled.div`
  display: flex;
  position: relative;
  
  & > input {
    padding-right: 40px;
  }
`

const InputChildrenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 12px;
`

export default InputChildren;
