import styled from "styled-components";
import {IntroduceTemplate} from "../../components/templates/introduce";

export const Introduce = () => {
    return (
        <IntroduceWrap>
            <IntroduceTemplate />
        </IntroduceWrap>
    )
}

const IntroduceWrap = styled.div`

`
