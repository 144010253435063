import styled from "styled-components";
import {ChangeEvent, ReactNode} from "react";
import Input from "../../atoms/input";
import {colors} from "../../../styles";

type props = {
    id?: string;
    htmlFor?: string;
    name?: string;
    width?: string;
    fileName?: string;
    accept?: string;
    children?: ReactNode;
    onInput?(e: ChangeEvent<HTMLInputElement>): void;
}

const InputFile = ({htmlFor, onInput, name, fileName, accept, width, children}: props) => {
    return (
        <InputFileWrap width={width}>
            <Input type={"text"} theme={'normal'} value={fileName} width={'100%'} disabled/>
            <LabelWrap htmlFor={htmlFor}>{children}</LabelWrap>

            <InputWrap type={'file'} name={name} id={htmlFor} onInput={onInput} accept={accept}/>
        </InputFileWrap>
    )
}

const InputFileWrap = styled.div<{ width?: string }>`
  ${({width}) => (width ? `width: ${width}` : '')};
  display: flex;
`

const InputWrap = styled.input`
  display: none;
`

const LabelWrap = styled.label`
  width: 20%;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  background-color: ${colors.black};
  color: ${colors.white};
`

export default InputFile;
