import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {colors} from "../../../styles";
import {useState} from "react";
import {MyInfoTemplate} from "./myinfo";
import {PasswordTemplate} from "./password";
import {useTranslation} from "react-i18next";

export const MyPageTemplate = () => {
    const {t} = useTranslation();
    const [type, setType] = useState(1);

    return (
        <LayoutTemplate>
            <MyPageWrap>
                <MyPageHeaderWrap>
                    <MyPageHeaderItem $active={type === 1} onClick={() => setType(1)}>{t('mypage.category1')}</MyPageHeaderItem>
                    <MyPageHeaderItem $active={type === 2} onClick={() => setType(2)}>{t('mypage.category2')}</MyPageHeaderItem>
                </MyPageHeaderWrap>

                {type === 1 && <MyInfoTemplate/>}
                {type === 2 && <PasswordTemplate/>}
            </MyPageWrap>
        </LayoutTemplate>
    )
}

const MyPageWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  padding: 80px 40px;
  gap: 20px;
`

const MyPageHeaderWrap = styled.ul`
  display: flex;
  width: 100%;
  gap: 20px;
  border-bottom: 1px solid ${colors.gray010};
`

const MyPageHeaderItem = styled.li<{ $active?: boolean }>`
  cursor: pointer;
  padding: 10px;
  font-weight: ${({$active}) => $active && 'bold'};
  border-bottom: ${({$active}) => $active && '3px solid #000'};
`

const MyPageContentWrap = styled.div`
  font-size: 14px;
  width: 100%;
  max-width: 360px;

  & > button {
    margin-top: 20px;
  }
`
