import styled from "styled-components";
import {SignupTemplate} from "../../components/templates/signup";

export const Signup = () => {
    return (
        <SignupWrap>
            <SignupTemplate />
        </SignupWrap>
    )
}

const SignupWrap = styled.div`

`
