export const colors = {
    main: '#0057FF',
    black: '#000000',
    red: '#EC2F23FF',
    white: '#FFFFFF',
    gray: '#DFDFDF',
    gray080: '#242424FF',
    gray030: '#9CA4ABFF',
    gray010: '#DEE3E8FF',
    gray005: '#F3F4F6FF',
    gray002: '#FAFAFBFF',
};

export const typography = (() => {
    const sizes = [10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 26, 28, 32, 40, 64, 72] as const;
    const weights = [
        ['light', 300],
        ['regular', 400],
        ['medium', 500],
        ['bold', 600],
    ] as const;

    type fontKey = `${(typeof weights)[number][0]}${(typeof sizes)[number]}`;
    const entries = sizes.flatMap((size) =>
        weights.map(([weightName, value]) => [
            `${weightName}${size}` as fontKey,
            `
                font-size: ${size}px;
                font-weight: ${value};
            `,
        ]),
    );
    const fontStyles: Record<fontKey, string> = Object.fromEntries(entries);
    return fontStyles;
})();

export type fontStyles = keyof typeof typography;
