import styled from "styled-components";
import {MyPageTemplate} from "../../components/templates/mypage";

export const MyPage = () => {
    return (
        <MyPageWrap>
            <MyPageTemplate />
        </MyPageWrap>
    )
}

const MyPageWrap = styled.div`

`
