import styled from "styled-components";
import {useEffect, useState} from "react";
import pagination from "../../../utils/pagination";
import {colors} from "../../../styles";
import {useNavigate} from "react-router-dom";

type props = {
    count: number,
    page: number,
    search?: string,
}

const Pagination = ({count, page, search}: props) => {
    const navigate = useNavigate();
    const [paginate, setPaginate] = useState(pagination(count, page || 1));

    useEffect(() => {
        setPaginate(pagination(count, Number(page) || 1));
    }, [count, page, search])

    return (
        <PaginationWrap>
            <PaginationPrevNextWrap disabled={paginate.preventPrevPage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                     onClick={() => !paginate.preventPrevPage && navigate(`?page=${paginate.bigPrevPage}&search=${search || ''}`)}
                     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="11 17 6 12 11 7"></polyline>
                    <polyline points="18 17 13 12 18 7"></polyline>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                     onClick={() => !paginate.preventPrevPage && navigate(`?page=${Number(page) - 1}&search=${search || ''}`)}
                     stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="feather feather-chevron-left">
                    <polyline points="15 18 9 12 15 6"/>
                </svg>
            </PaginationPrevNextWrap>

            {
                paginate.pageBlock.map((i, index) => {
                    return (
                        <PaginationGoToButton
                            onClick={() => navigate(`?page=${i}&search=${search || ''}`)}
                            $active={(Number(page) || 1) === i}
                            key={index}> {i} </PaginationGoToButton>
                    )
                })
            }

            <PaginationPrevNextWrap disabled={paginate.preventNextPage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                     onClick={() => !paginate.preventNextPage && navigate(`?page=${Number(page) + 1}&search=${search || ''}`)}
                     stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="feather feather-chevron-right">
                    <polyline points="9 18 15 12 9 6"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                     onClick={() => !paginate.preventNextPage && navigate(`?page=${paginate.bigNextPage}&search=${search || ''}`)}
                     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                </svg>
            </PaginationPrevNextWrap>
        </PaginationWrap>
    )
}

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;

  & svg {
    cursor: pointer;
  }
`

const PaginationGoToButton = styled.button<{ $active: boolean }>`
  border: ${({$active}) => $active && '1px solid #eee'};
  color: ${({$active}) => $active ? colors.red : ''};
  font-size: 14px;
  width: 24px;
  height: 24px;
`

const PaginationPrevNextWrap = styled.div<{ disabled: boolean }>`
  display: flex;

  & svg {
    color: ${({disabled}) => disabled ? '#aaa' : '#000'};
    cursor: ${({disabled}) => disabled ? 'initial' : 'pointer'};
  }
`

export default Pagination;
