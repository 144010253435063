import {colors, fontStyles} from "../../../styles";
import styled from "styled-components";
import {useState, Dispatch, SetStateAction, ReactNode} from "react";
import {sizes} from "../../../types";
import {useTranslation} from "react-i18next";

type props = {
    infoKey?: string;
    items: itemProps[];
    size?: sizes;
    isOpen?: boolean;
    setInfo?: Dispatch<SetStateAction<any>>;
    fontStyle?: fontStyles;
    value?: string | number;
}

type itemProps = {
    value: string | number,
    text: ReactNode,
}

type styleProps = {
    $isOpen?: boolean;
    size?: sizes;
};

const SelectLang = ({infoKey, setInfo, items, value, size}: props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [select, setSelect] = useState<ReactNode>(value ? value : items[0].text);
    const { i18n } = useTranslation();

    return (
        <SelectWrap>
            <SizeSelectWrap size={size}>
                <SelectTitleWrap $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                    {select}
                </SelectTitleWrap>
            </SizeSelectWrap>

            {
                isOpen &&
                <SelectItemsWrap>
                    {
                        items && items.map((i, index) => {
                            return (
                                <SizeSelectItemWrap key={index} $active={i.value === select} $last={items.length === (index + 1)} size={size}
                                                    onClick={async () => {
                                                        setIsOpen(false);
                                                        setSelect(i.text);
                                                        await i18n.changeLanguage(i.value.toString());

                                                        if (setInfo && infoKey) setInfo((prev: any) => ({
                                                            ...prev,
                                                            [infoKey]: i.value,
                                                        }));
                                                    }}>
                                    {i.text}
                                </SizeSelectItemWrap>
                            )
                        })
                    }
                </SelectItemsWrap>
            }
        </SelectWrap>
    );
}

const SelectWrap = styled.div<styleProps>`
  position: relative;
  color: ${colors.black};
`

const SizeSelectWrap = styled(SelectWrap)<styleProps>`
  min-width: 110px;
  
  ${({size}) => {
    switch (size) {
        case sizes.sm:
            return `
            height: 45px;
          `
        default:
            return `
            height: 45px;
        `;
    }
}};
`

const SelectTitleWrap = styled.span<styleProps>`
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
  white-space: nowrap;
  padding: 10px;
  height: 100%;
  cursor: pointer;
  gap: 10px;
`

const SelectItemsWrap = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${colors.white};
  top: 100%;
`

const SelectItemWrap = styled.div<{ $active?: boolean, $last?: boolean }>`
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f7f8f9;
  }
`

const SizeSelectItemWrap = styled(SelectItemWrap)<styleProps>`
  display: flex;
  gap: 10px;
  padding: 10px;
  
  & > img {
    width: 48px;
  }
  
  ${({size}) => {
    switch (size) {
        case sizes.sm:
            return `
            height: 45px;
          `
        default:
            return `
            height: 45px;
        `;
    }
}};
`

export default SelectLang;
