import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Button, {buttonThemes} from "../../atoms/button";
import {FlexWrap} from "../../../styles/component";
import {useNavigate} from "react-router-dom";
import comp from "../../../assets/images/comp.png";

export const SignupCompTemplate = () => {
    const navigate = useNavigate();

    return (
        <LayoutTemplate>
            <SignupCompWrap>
                <img src={comp} alt={'comp'}/>

                <h2>회원가입이 완료 되었습니다!</h2>
                <span>사이트를 이용해 주셔서 감사합니다.</span>

                <FlexWrap $gap={20} $width={100}>
                    <Button theme={buttonThemes.black} width={'100%'} onClick={() => navigate('/login')}>로그인</Button>
                    <Button theme={buttonThemes.black} width={'100%'} onClick={() => navigate('/')}>홈으로 이동</Button>
                </FlexWrap>
            </SignupCompWrap>
        </LayoutTemplate>
    )
}

const SignupCompWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 150px 40px;
  
  & > h2 {
    margin-top: 50px;
  }

  & button {
    font-size: 14px;
    margin-top: 80px;
  }
`
