import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Button from "../../atoms/button";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const FindIdAndPassword = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onFindId = useCallback(()=>{
        navigate('/find/findId')
    },[])

    const onFindPassword = useCallback(()=>{
        navigate('/find/findPw')
    },[])

    return (
        <LayoutTemplate>
            <FindWrap>
                <FindItem>
                    <strong> {t('signup.findId')} </strong>
                    <span> {t('signup.findIdValidation')} </span>
                    <Button onClick={() => onFindId()}>{t('signup.submit')}</Button>
                </FindItem>

                <SeparateWrap/>

                <FindItem>
                    <strong> {t('signup.findPw')} </strong>
                    <span> {t('signup.findPwValidation')} </span>
                    <Button onClick={() => onFindPassword()}>{t('signup.submit')}</Button>
                </FindItem>
            </FindWrap>
        </LayoutTemplate>
    )
}



const FindWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  margin: 10vw auto;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  background-color: #fff;
`

const FindItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SeparateWrap = styled.div`
  margin: 3vw 0;
  border-top: 1px solid #eee;
`

