import axios from 'axios';
import {useQuery} from "react-query";
import {useCallback} from "react";

export const useFindIntroduce = () => {
    const api = useCallback(() => {
        return axios.get(`/vendor/api/v1/introduce/`);
    }, []);

    return useQuery(
        ['introduce'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}
