import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import {ChangeEvent, useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import {useFindInvoice, useFindInvoices, useUpdateInvoice} from "../../../api/invoice";
import Button, {buttonThemes} from "../../atoms/button";
import {shapes, sizes} from "../../../types";
import {FlexWrap} from "../../../styles/component";
import {invoiceTypes} from "../../../types/invoice";
import {useTranslation} from "react-i18next";
import ButtonFile from "../../molecules/buttonFile";
import {useQueryClient} from "react-query";
import {useSetRecoilState} from "recoil";
import {modalState, showModal} from "../../../recoil/modal";
import Tag from "../../atoms/tag";

type itemType = {
    name: string;
    amount: string;
    price: string;
}

export const InvoiceItemsModal = ({invoiceSeq}: { invoiceSeq: string }) => {
    const {data: invoice, isLoading} = useFindInvoice({seq: invoiceSeq});

    const columns = useMemo(() => [
        {header: '이름', accessor: 'name', width: '33%'},
        {header: '개수', accessor: 'amount', width: '33%'},
        {header: '가격', accessor: 'price', width: '34%'},
    ], []);

    const data = useMemo(() => invoice?.items && invoice?.items.length > 0 ? invoice?.items.map((x: itemType) => {
        return x;
    }) : '', [invoice]);

    return (
        <FlexWrap $width={100}>
            <Table columns={columns} data={data} isLoading={isLoading}/>
        </FlexWrap>
    )
}

export const InvoiceTemplate = () => {
    const {data: invoices, isLoading} = useFindInvoices();
    const {mutate} = useUpdateInvoice();
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const setModal = useSetRecoilState(modalState);

    const onInvoiceItemsModal = useCallback((seq: string) => {
        showModal(setModal, {children: <InvoiceItemsModal invoiceSeq={seq}/>})
    }, [])

    const onUpload = useCallback((e?: ChangeEvent<HTMLInputElement>, seq?: string) => {
        const name = e?.target.name as string;
        const value = e?.target.files && e?.target.files[0];

        if (value) {
            const formData = new FormData();
            formData.append('data', JSON.stringify({seq}));
            formData.append(name, value);

            mutate(formData, {
                onSuccess: () => {
                    alert('정상적으로 업로드되었습니다.');
                    queryClient.invalidateQueries(['invoice']);
                },
            })
        }
    }, []);

    const onDownload = useCallback(({url, name}: { url: string, name: string }) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = `심플차이나_${name}_파일`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }, []);

    const columns = useMemo(() => [
        {header: '', accessor: 'seq', width: '5%'},
        {header: '인보이스<br />발행여부', accessor: 'status', width: '10%'},
        {header: '사업자명<br/>(영문)', accessor: 'bizEngName', width: '15%'},
        {header: '사업자명<br/>(한글)', accessor: 'bizKrName', width: '15%'},
        {header: '사업자 주소<br/>(영문)', accessor: 'bizEngAddress', width: '10%'},
        {header: '대표자명<br/>(영문)', accessor: 'bizEngCeo', width: '10%'},
        {header: '대표자명<br/>(한글)', accessor: 'bizKrCeo', width: '10%'},
        {header: '사업자 번호', accessor: 'bizNumber', width: '10%'},
        {header: '연락처', accessor: 'bizTelephone', width: '10%'},
        {header: '신청금액 ($)', accessor: 'price', width: '10%'},
        {header: '품목', accessor: 'items', width: '10%'},
        {header: '계좌사본', accessor: 'chinaAccountFile', width: '10%'},
        {header: '인보이스', accessor: 'invoiceFile', width: '10%'},
        {header: '송금전문', accessor: 'junmoonFile', width: '10%'},
        {header: '수입증빙', accessor: 'jeungbingFile', width: '10%'},
        {header: '날짜', accessor: 'insertDate', width: '10%'},
    ], []);

    const data = useMemo(() => invoices?.data && invoices?.data.length > 0 ? invoices?.data.map((x: invoiceTypes) => {
        return {
            ...x,
            bizEngAddress : <BizAddress>{x.bizEngAddress}</BizAddress>,
            status: x.status === 0 ? (
                <>
                    <Tag theme={'orange'}>미발행</Tag>
                </>
            ) : x.status === 1 ? (
                <>
                    <Tag theme={'navy'}>발행</Tag>
                </>

            ) : (
                <>
                    <Tag theme={'red'}>완료</Tag>
                </>

            ),
            items:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.black} size={sizes.xs} shape={shapes.circle}
                            onClick={() => x.seq && onInvoiceItemsModal(x.seq)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="lucide lucide-search">
                            <circle cx="11" cy="11" r="8"/>
                            <path d="m21 21-4.3-4.3"/>
                        </svg>
                    </Button>
                </FlexWrap>,
            chinaAccountFile:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.black} size={sizes.xs} shape={shapes.circle}
                            onClick={() => onDownload({url: x.chinaAccountFile || '', name: '계좌사본'})}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                            <polyline points="7 10 12 15 17 10"/>
                            <line x1="12" x2="12" y1="15" y2="3"/>
                        </svg>
                    </Button>
                </FlexWrap>,
            invoiceFile:
                x.invoiceFile &&
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.black} size={sizes.xs} shape={shapes.circle}
                            onClick={() => onDownload({url: x.invoiceFile || '', name: '인보이스'})}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                            <polyline points="7 10 12 15 17 10"/>
                            <line x1="12" x2="12" y1="15" y2="3"/>
                        </svg>
                    </Button>
                </FlexWrap>,
            junmoonFile:
                !x.junmoonFile ?
                    <FlexWrap $center={true}>
                        <ButtonFile id={'junmoonFile'} name={'junmoonFile'} theme={buttonThemes.black} size={sizes.xs}
                                    shape={shapes.circle}
                                    accept={'image/*'} onInput={(e) => onUpload(e, x.seq)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-upload">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                <polyline points="17 8 12 3 7 8"/>
                                <line x1="12" x2="12" y1="3" y2="15"/>
                            </svg>
                        </ButtonFile>
                    </FlexWrap> :
                    <FlexWrap $center={true}>
                        <Button theme={buttonThemes.black} size={sizes.xs} shape={shapes.circle}
                                onClick={() => onDownload({url: x.junmoonFile || '', name: '송금전문'})}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                <polyline points="7 10 12 15 17 10"/>
                                <line x1="12" x2="12" y1="15" y2="3"/>
                            </svg>
                        </Button>
                    </FlexWrap>,
            jeungbingFile:
                !x.jeungbingFile ?
                    <FlexWrap $center={true}>
                        <ButtonFile id={'jeungbingFile'} name={'jeungbingFile'} theme={buttonThemes.black}
                                    size={sizes.xs} shape={shapes.circle}
                                    accept={'image/*'} onInput={(e) => onUpload(e, x.seq)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-upload">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                <polyline points="17 8 12 3 7 8"/>
                                <line x1="12" x2="12" y1="3" y2="15"/>
                            </svg>
                        </ButtonFile>
                    </FlexWrap> :
                    <FlexWrap $center={true}>
                        <Button theme={buttonThemes.black} size={sizes.xs} shape={shapes.circle}
                                onClick={() => onDownload({url: x.jeungbingFile || '', name: '수입증빙'})}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                <polyline points="7 10 12 15 17 10"/>
                                <line x1="12" x2="12" y1="15" y2="3"/>
                            </svg>
                        </Button>
                    </FlexWrap>,
            insertDate: x.insertDate?.slice(0, 10),
        };
    }) : '', [invoices]);

    return (
        <LayoutTemplate>
            <InvoiceWrap>
                <h2>{t('invoice.RequestDetails')}</h2>

                <Table columns={columns} data={data} isLoading={isLoading}/>
            </InvoiceWrap>
        </LayoutTemplate>
    )
}

const InvoiceWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  gap: 30px;
`

const BizAddress = styled.div`
  width: 200px;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
