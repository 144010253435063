import axios from 'axios';
import {useQuery} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {noticeTypes} from "../types/notice";

export const useFindExchange = () => {
    const api = useCallback(() => {
        return axios.get(`/vendor/api/v1/exchange`);
    }, []);

    return useQuery(
        ['exchange'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}
