import styled from "styled-components";
import {LayoutTemplate} from "../layout";
import Input from "../../atoms/input";
import Button, {buttonThemes} from "../../atoms/button";
import {sizes} from "../../../types";
import InputFile from "../../molecules/inputFile";
import {Danger, FlexWrap} from "../../../styles/component";
import {useCallback, useState} from "react";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {useInsertUser} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const SignupTemplate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {mutate, isLoading} = useInsertUser();

    const [info, setInfo] = useState<Record<string, any>>({
        email: {value: '', require: true, validate: false, pattern: regex.email},
        password: {value: '', require: true, validate: false, pattern: regex.password},
        nameKr: {value: '', require: true, validate: false, pattern: false},
        nameEng: {value: '', require: true, validate: false, pattern: false},
        telephone1: {value: '', require: true, validate: false, pattern: false},
        telephone2: {value: '', require: true, validate: false, pattern: false},
        telephone3: {value: '', require: true, validate: false, pattern: false},
        bizKrName: {value: '', require: true, validate: false, pattern: false},
        bizEngName: {value: '', require: true, validate: false, pattern: false},
        bizEngAddress: {value: '', require: true, validate: false, pattern: false},
        bizNumber: {value: '', require: true, validate: false, pattern: false},
        bizFile: {value: '', require: true, validate: false},
        passportFile: {value: '', require: true, validate: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();
        info.telephone = `${info.telephone1}${info.telephone2}${info.telephone3}`;

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.bizFile) formData.append('bizFile', info.bizFile);
        if (info.passportFile) formData.append('passportFile', info.passportFile);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/signup/complete');
            },
        });
    }, [info]);

    return (
        <LayoutTemplate>
            <SignupWrap>
                <h2>{t('signup.title')}</h2>

                <LoginInputItemsWrap>
                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.email')}</span>
                        <Input width={'100%'} name={'email'} value={info.email.value} onChange={onChangeInfo}/>
                        {!info.email.validate && <Danger>{t('signup.emailDanger')}</Danger>}
                        <small> * {t('signup.emailDesc')}</small>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.password')}</span>
                        <Input type={'password'} width={'100%'} name={'password'} value={info.password.value} onChange={onChangeInfo}/>
                        {!info.password.validate && <Danger>{t('signup.passwordDanger')}</Danger>}
                        <small> * {t('signup.passwordDesc')}</small>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.nameKr')}</span>
                        <Input width={'100%'} name={'nameKr'} value={info.nameKr.value} onChange={onChangeInfo}/>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.nameEng')}</span>
                        <Input width={'100%'} name={'nameEng'} value={info.nameEng.value} onChange={onChangeInfo}/>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.telephone')}</span>
                        <FlexWrap $gap={10} $center={true}>
                            <Input type={'number'} width={'100%'} name={'telephone1'} value={info.telephone1.value} onChange={onChangeInfo}/>
                            -
                            <Input type={'number'} width={'100%'} name={'telephone2'} value={info.telephone2.value} onChange={onChangeInfo}/>
                            -
                            <Input type={'number'} width={'100%'} name={'telephone3'} value={info.telephone3.value} onChange={onChangeInfo}/>
                        </FlexWrap>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.bizKrName')}</span>
                        <Input width={'100%'} name={'bizKrName'} value={info.bizKrName.value} onChange={onChangeInfo}/>
                        <small> * {t('signup.bizKrNameDesc')}</small>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.bizEngName')}</span>
                        <Input width={'100%'} name={'bizEngName'} value={info.bizEngName.value} onChange={onChangeInfo}/>
                        <small> * {t('signup.bizEngNameDesc')}</small>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.bizEngAddress')}</span>
                        <Input width={'100%'} name={'bizEngAddress'} value={info.bizEngAddress.value} onChange={onChangeInfo}/>
                        <small> * {t('signup.bizEngAddress')}</small>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.bizNumber')}</span>
                        <Input width={'100%'} name={'bizNumber'} value={info.bizNumber.value} onChange={onChangeInfo}/>
                        <small> * {t('signup.bizNumber')}</small>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.bizFile')}</span>
                        <InputFile htmlFor={'bizFile'} name={'bizFile'} accept={'image/*'}
                                   fileName={info.bizFile?.value?.name && info.bizFile?.value?.name}
                                   onInput={onChangeInfo}>첨부</InputFile>
                    </FlexWrap>

                    <FlexWrap $columns={true} $gap={10}>
                        <span>{t('signup.passportFile')}</span>
                        <InputFile htmlFor={'passportFile'} name={'passportFile'} accept={'image/*'}
                                   fileName={info.passportFile?.value?.name && info.passportFile?.value?.name}
                                   onInput={onChangeInfo}>첨부</InputFile>
                    </FlexWrap>
                </LoginInputItemsWrap>

                <Button width={'100%'} size={sizes.md} $isAble={onValidate()} onClick={onSubmit}>{t('signup.button')}</Button>
            </SignupWrap>
        </LayoutTemplate>
    )
}

const SignupWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 80px 40px;

  & > span {
    font-size: 32px;
  }

  & > button {
    font-size: 14px;
  }
`

const LoginInputItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
`
